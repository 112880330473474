<template>
  <div class="innovationContest">
    <div v-loading="loading"></div>
    <img class="Ellipse24" src="@/assets/PC/InnovationContest/Ellipse 24.png" alt="" />
    <div class="innovationContest-banner">
      <!-- <swiper :banners="bannerList"></swiper> -->
    </div>
    <div class="innovationContest-module1">
      <h1 class="tc innovationContest-title backtitle"><span>论剑 2021</span></h1>
      <!-- 参赛作品 -->
      <div v-if="currentWorksList.length" class="innovationContest-entry-works" id="entryWorkBox" ref="entryWork">
        <h2 class="tc innovationContest-subtitle">决赛作品回看</h2>
        <previous-works-swiper :PreviousWorksList="currentWorksList" :IsBg="true"></previous-works-swiper>
      </div>
      <!-- 参赛选手 -->
      <div class="innovationContest-entry-works">
        <entry-works></entry-works>
      </div>
      <!-- 参赛评委 -->
      <div class="innovationContest-participating-the-judges">
        <Participating-the-judges />
      </div>
    </div>
    <!-- 往届作品 -->
    <div v-if="previousWorksList.length" class="innovationContest-Previous-works">
      <h1 class="tc innovationContest-title backtitle"><span>论剑 2020</span></h1>
      <h2 class="tc innovationContest-subtitle">决赛作品回看</h2>
      <previous-works-swiper :PreviousWorksList="previousWorksList" :IsBg="false"></previous-works-swiper>
    </div>
    <!-- 右侧悬浮窗 -->
    <div class="innovationContest-right-box co-fff" v-show="false">
      <div class="innovationContest-right-img cur tr mb" @click="GetFinalWork">
        <img src="@/assets/PC/InnovationContest/look.png" alt="" width="100%" />
      </div>
      <div>
        <div class="innovationContest-right-child" @click="Invite">
          <div class="tc">邀请有礼</div>
        </div>
        <div class="innovationContest-right-child" @click="ShowRoles">
          <div class="tc">积分获取</div>
        </div>
        <div class="innovationContest-right-child" @click="OfficialAccount">
          <div class="tc">剑维软件<br>官微</div>
        </div>
      </div>
    </div>
    <!-- <div class="right-box-two">
      <div class="innovationContest-right-img cur tr" @click="drawGift">
        <img src="@/assets/PC/InnovationContest/gift.png" alt="" width="100%" />
      </div>
      <div class="innovationContest-right-img cur tr" @click="questionnaire">
        <img src="@/assets/PC/InnovationContest/form.png" alt="" width="100%" />
      </div>
      <div class="innovationContest-right-img cur tr" @click="GetFinalWork" :class="clientWidth > 750 ? 'mt15' : ''">
        <img src="@/assets/PC/InnovationContest/look.png" alt="" width="100%" />
      </div>
    </div> -->

    <!-- 大赛规则 -->
    <extremeRules :isShow="isShow" @change="changes"></extremeRules>
    <!-- 报名弹窗 -->
    <toolTipBox :Show="liveShow" :Content="liveContent" :Type="liveType" @liveChange="liveChanges"></toolTipBox>
    <!-- 邀请好友弹窗 -->
    <div v-show="showInvite" class="shade flex0">
      <div class="invite-friends-content tc">
        <div class="invite-codes">
          <div class="invite-code-box">
            <div v-if="moduleLoading" class="module-load">
              <van-loading type="spinner" color="#000" />
            </div>
            <img :src="InviteCodeImg" alt="" width="100%" />
          </div>
        </div>
        <div class="invite-introduce mt15" style="margin-bottom: 5px">扫描二维码，生成专属海报。</div>
        <div class="invite-introduce">邀请好友注册，获取更多积分，参与<b class="co-6b1">大赛抽奖</b>，更多好礼等你来拿！</div>
        <i class="el-icon-circle-close close-alert co-fff cur" @click="closeAlert(1)"></i>
      </div>
    </div>
    <!-- 剑盟软件公众号弹窗 -->
    <div v-show="OfficialAccountState" class="shade flex0">
      <div class="invite-friends-content tc">
        <div class="invite-codes">
          <div class="invite-code-box">
            <img src="@/assets/PC/InnovationContest/OfficialAccount.png" alt="" width="100%">
          </div>
        </div>
        <div class="invite-introduce mt15">获取更多最新行业资讯，<br/>请扫描二维码关注剑维软件官方微信。</div>
        <i class="el-icon-circle-close close-alert co-fff cur" @click="closeAlert(2)"></i>
      </div>
    </div>
    <!-- 抽奖弹窗 -->
    <!-- <div v-show="drawState" class="shade flex0">
      <div style="position: relative; margin: 0 15px">
        <i class="el-icon-circle-close close-alert co-fff cur" @click="closeAlert(3)" style="z-index: 1"></i>
        <draw-module :drawNumCopy="drawNumCopy" @changeDrawNum="changeDrawNum" ></draw-module>
      </div>
    </div> -->

    <!-- 问卷调查弹窗 -->
    <!-- <questionnaire :questionnaireState="questionnaireState" @changeQuestionnaireState="closeQuestionAlert"></questionnaire> -->
    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { common_msg_update, GetWxShares, GetShareData } from "@/utils/common";

import BannerLive from "@/components/Live/index.vue";
import BannerSwiper from "./bannerSwiper.vue";
import swiper from "@/components/Swiper.vue";
import PreviousWorksSwiper from "./PreviousWorksSwiper.vue";
import EntryWorks from "./EntryWorks.vue";
import ParticipatingTheJudges from "./ParticipatingTheJudgesSwiper.vue";
import extremeRules from "./extremeRules.vue";
import toolTipBox from "./toolTipBox.vue";
import questionnaire from "./questionnaire.vue";
import drawModule from "@/components/drawModule/index.vue";
import shadeAlert from "@/components/shadeAlert/index.vue";

import {
  LiveAgendas,
  LiveBanner,
  Finalwork,
  LiveEntriesHistory,
  MakeLive,
  InviteQRCode,
  DrawNum,
} from "@/api/InnovationContest.js";

export default {
  name: "innovationContest",
  data() {
    return {
      isShow: false,
      // !展示大赛报名弹窗
      liveShow: false,
      liveContent: "请登录后再来报名吧!",
      liveType: 1,
      loading: true,
      // !banner展示图
      bannerList: [],
      // !banner指示器文字
      bannerText: [],
      // !大会介绍
      themes: `
        <p><b class="co-6b1">AVEVA 剑维软件 大中华区总经理 万世平</b></p>
        <p>创新，可以是0到1的突破，也可以是1到N的无限延展。同时，创新需要凝聚整个行业生态系统的智慧，群策群力。因此，AVEVA 剑维软件一直与本地合作伙伴及客户紧密合作，以共同应对市场日新月异的挑战。</p>
        <p><b class="co-6b1">论剑 2021 工业数字化转型技术创新大赛</b> 是一个很好的平台，将我们的产品或解决方案融合多方的技术，开发数字化转型路径的无限可能性，帮助行业解决当下复杂的应用问题，并为长远可持续发展出谋献策。</p>
        <p>2022年1月11日，<b class="co-6b1">论剑 2021 工业数字化转型技术创新大赛</b>，高效运营、工程设计2大类解决方案作品应用，6位专家评委助阵，12位选手精彩PK，他们将用一天的时间来比武论剑，一较高下。</p>
      `,
      // !解决方案图片切换
      solutionState: true,
      // !解决方案图片链接
      solutionImgLinks: [
        "https://www.aveva.cn/perspectives/campaigns/operational-efficiency/", // top
        "https://www.aveva.cn/perspectives/campaigns/engineering-the-plant-of-the-future/", // bottom
      ],
      // !大会日程
      agenda: [],
      // !参赛选手
      playerList: [],
      // 决赛作品
      currentWorksList: [],
      // !往届作品
      previousWorksList: [],
      // !参赛作品
      entryWorksListData: [],
      entryWorksList: [],
      activeType: 0,
      // !展示邀请好友二维码
      showInvite: false,
      // !邀请二维码图片
      InviteCodeImg: "",
      // !模块loading
      moduleLoading: true,
      // !展示剑维软件公众号二维码
      OfficialAccountState: false,
      // !抽奖弹窗
      drawState: false,
      // !抽奖数量
      drawNumCopy: {
        num: 0,
        money: 0,
      },
      // !问卷弹窗
      questionnaireState: false,
      // !弹窗配置
      shadeConfig: {
        show: false, // 展示隐藏弹窗
        closeIcon: true, // 是否需要关闭按钮
        title: "",
        text: "", // 弹窗内容
        btnType: "", // 按钮形式
        btnList: [],
      },
      // !延时定时器
      timer: null
    };
  },
  created() {
    if (this.$route.query.u) {
      this.$store.commit("inviterId", this.$route.query.u);
    }
  },
  mounted() {
    this.loading = false;
    this.LiveAgenda();
    this.GetBannerList();
    this.GetCurrentWorks();
    this.GetPreviousWorks();
    GetShareData(1, 'Live', '') 
     const share = {
      title: '论剑 2021',
      desc: ''
    }
    GetWxShares()
  },
  methods: {
    // !banner 接口
    GetBannerList() {
      LiveBanner().then((res) => {
        if (res.status === 1) {
          res.data.map((item) => {
            if (this.clientWidth > 750) {
              this.bannerList.push({
                imgUrl: item.imgUrl,
                url: item.redirectUrl,
              });
            } else {
              this.bannerList.push({
                imgUrl: item.imgUrl_m,
                url: item.redirectUrl,
              });
            }

            this.bannerText.push({
              subTitle: item.subTitle,
              title: item.title,
            });
          });
        } else {
          this.$toast(res.message);
        }
      });
    },
    // !解决方案图片切换
    solutionStates(type) {
      if (this.clientWidth > 750) {
        if (type == 0) {
          this.solutionState = true;
        } else {
          this.solutionState = false;
        }
      }
    },
    // !解决方案图片链接
    solutionImgLink(type) {
      if (this.clientWidth > 750) {
        window.open(this.solutionImgLinks[type]);
      } else {
        window.location.href = this.solutionImgLinks[type];
      }
    },
    // !大赛日程接口
    LiveAgenda() {
      LiveAgendas().then((res) => {
        if (res.status === 1) {
          this.agenda = res.data;
        } else {
          this.$toast(res.message);
        }
      });
    },
    // 决赛作品接口
    GetCurrentWorks() {
      Finalwork().then((res) => {
        if (res.status === 1) {
          this.currentWorksList = res.data;
        } else {
          this.$toast(res.message);
        }
      });
    },
    // !往届作品接口
    GetPreviousWorks() {
      LiveEntriesHistory().then((res) => {
        if (res.status === 1) {
          this.previousWorksList = res.data;
          this.previousWorksList.map(item => {
            item.userName = item.name
          })
        } else {
          this.$toast(res.message);
        }
      });
    },
    // !大赛规则
    ShowRoles() {
      this.isShow = true;
    },
    // !邀请好友
    Invite() {
      if (this.clientWidth > 750) {
        this.showInvite = true;
        if (!this.InviteCodeImg) {
          this.InviteQRCode();
        }
      } else {
        this.$router.push("/FissionPosters");
      }
    },
    // !关闭弹窗  type( 1：邀请好友弹窗 2: 剑维软件公众号弹窗 )
    closeAlert(type) {
      if (type == 1) {
        this.showInvite = false;
      } else if (type == 2) {
        this.OfficialAccountState = false;
      } else if (type == 3) {
        this.drawState = false;
      }
    },
    // !关闭问卷弹窗
    closeQuestionAlert(state) {
      this.questionnaireState = state;
    },
    // !剑维软件公众号展示
    OfficialAccount() {
      this.OfficialAccountState = true;
    },
    // !大赛报名
    live() {
      if (!this.$store.state.token) {
        this.liveShow = true;
      } else {
        MakeLive().then((res) => {
          this.$toast(res.message);
          if (res.status === 1) {
            common_msg_update();
          }
        });
      }
    },
    changes(val) {
      this.isShow = val;
    },
    liveChanges(val) {
      this.liveShow = val;
    },
    // !邀请好友二维码
    InviteQRCode() {
      InviteQRCode().then((res) => {
        if (res.status === 1) {
          this.InviteCodeImg = res.data;
          this.moduleLoading = false;
        } else {
          this.$$toast(res.message);
        }
      });
    },
    // !抽奖弹窗展示
    drawGift() {
      if (!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来抽奖吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: "已有剑盟账号，前往登录",
              link: "/Login?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
            {
              text: "没有账号，立即注册",
              link:
                "/Register?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
          ],
        };
        return false;
      }
      this.drawState = true;
      DrawNum().then((res) => {
        if (res.status === 1) {
          this.drawNumCopy = res.data;
        } else {
          this.$toast(res.message);
        }
      });
    },
    // !抽奖次数同步
    changeDrawNum(num) {
      this.drawNumCopy = num;
    },
    // !问卷调查弹窗
    questionnaire() {
      if (!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来填写问卷吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: "已有剑盟账号，前往登录",
              link: "/Login?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
            {
              text: "没有账号，立即注册",
              link:
                "/Register?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
          ],
        };
        return false;
      }
      this.questionnaireState = true;
    },
    // !关闭登录指引弹窗
    closeAlertChange(state) {
      this.shadeConfig.show = state;
    },
    // !日程查看回放
    goLiveDetail(id) {
      this.$router.push('/Home/LivePlayback?id=' + id)
    },
    // !决赛作品
    GetFinalWork() {
      if(this.clientWidth < 769) {
        document.getElementById('entryWorkBox').scrollIntoView()
      } else {
        let tops = this.$refs.entryWork.offsetHeight + 100
        if(document.documentElement.scrollTop <= this.$refs.entryWork.offsetHeight) {
          const timeTop = setInterval(() => {
            document.documentElement.scrollTop += 50;
            if ((document.documentElement.scrollTop || document.body.scrollTop) >= tops) {
              clearInterval(timeTop);
            }
          }, 10);
        }

        if(document.documentElement.scrollTop >= this.$refs.entryWork.offsetHeight) {
          const timeTop = setInterval(() => {
            document.documentElement.scrollTop -= 50;
            if ((document.documentElement.scrollTop || document.body.scrollTop) <= tops) {
              clearInterval(timeTop);
            }
          }, 10);
        }
      }
    },
    // !特效
    sendLove(evt) {
      let elm = evt.target.children[0];
      let rect = elm.getBoundingClientRect();
      let em = parseFloat(getComputedStyle(elm).fontSize);

      createLove(this);
    },
    fadeLove(index) {
      if (this.loves[index] !== null) {
        this.loves[index].show = false;
      }
    },
  },
  computed: {
    ...mapState(["Infos", "clientWidth", "token"]),
  },
  components: {
    BannerLive,
    BannerSwiper,
    swiper,
    EntryWorks,
    PreviousWorksSwiper,
    ParticipatingTheJudges,
    extremeRules,
    toolTipBox,
    questionnaire,
    drawModule,
    shadeAlert,
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>