<template>
  <div class="Live" :class="clientWidth > 768 ? 'flex' : ''">
    <xy-load v-if="liveLoad"></xy-load>
    <div class="live-l " style="position: relative;">
      <div class="barrage" id="barrage" ref="barrage" v-if="LivePages.barrageState">
        <v-barrage :arr="barrageList"
          :isPause="isPause"
          :percent="100">
        </v-barrage>
      </div>
      <div style="position: relative;">
        <!-- <div v-if="LivePages.isChannelLiving && LivePlayStartState" class="live-play-btn flex0">
          <img src="@/assets/PC/fenceDetail/player.png" alt="" class="cur" @click="LivePlayStart">
        </div> -->
        <div v-if="LivePlayStartState" class="live-play-btn flex0">
          <img src="@/assets/PC/fenceDetail/player.png" alt="" class="cur" @click="LivePlayStart">
        </div>
        <div id="J_prismPlayer"></div>
        <!-- <div v-if="!LivePages.isChannelLiving" class="live-content">
          <img :src="LivePages.LiveImage" alt="" width="100%">
        </div> -->
      </div>
      <div class="flex">
        <div class="live-introduce" style="width: 100%">
          <div class="flex6">
            <h1 class="mb10"><span v-if="LivePages.isChannelLiving" class="tag-living">正在直播</span> {{ LivePages.name }}</h1>
            <div class="ml5">
              <div class="live-liking cur flex0" @click="liveLiking">
                <ttt></ttt>
                <!-- <img src="@/assets/PC/InnovationContest/LiveLike.png" alt="" width="100%" :class="clickAnimate ? 'live-liking-animate': ''"> -->
              </div>
              <div class="live-living-num co-6b1 mb tc" style="user-select: none;">{{livingNum > 9999 ? (livingNum / 10000).toFixed(1) + 'w' : livingNum }}</div>
            </div>
          </div>
          <div class="flex3">
            <p class="flex2">
              <van-icon name="eye" color="#6b18a8" class="mr" :class="clientWidth > 768 ? 'mt5' : ''"/> 
              观看量：{{ LivePages.viewNum }}
            </p>
            <p class="flex2">
              弹幕 <van-switch class="ml" :value="LivePages.barrageState" size="14px" active-color="#6b18a8"  @input="onInput_BarrageState"/>
            </p>
            <div class="co-6b1" v-if="clientWidth < 768">
              <p v-if="LivePages.chatShow" class="flex2" @click="LivePages.chatShow = false">
                收起聊天 <van-icon name="arrow-up" />
              </p>
              <p v-else class="flex2" @click="LivePages.chatShow = true">
                展开聊天 <van-icon name="arrow-down" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="LivePages.chatShow" class="live-r" :class="clientWidth > 768 ? 'ml10' : ''">
      <div class="live-comment">
        <div class="live-comment-title">
          聊天互动
        </div>
        <div class="live-comment-list">
          <el-scrollbar style="height:100%" ref="scrollBox">
            <div v-for="(item, index) in LivePages.commentList" :key="item.id" class="live-comment-item">
              <p v-if="dateState(index, item.dateline)" class="tc mb5" style="color: #BBB">{{ dateConversion(item.dateline) }}</p>
              <div class="flex">
                <div class="live-comment-item-userImg">
                  <img :src="item.avatar" alt="">
                </div>
                <div class="ml10">
                  <div class="live-comment-item-user">{{ item.username }}</div>
                  <div class="live-comment-item-message">
                    <div v-if="item.message.includes('em_new_gif')">
                      <img :src="emoji(item.message)" alt="" class="live-comment-item-emoji">
                    </div>
                    <div v-else-if="item.msg_type === 50">
                      <img :src="item.message" alt="" class="live-comment-item-img">
                    </div>
                    <div v-else>{{ item.message }}</div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="live-comment-operation flex">
          <div class="flex2 ml10 mr10">
            <el-popover placement="top-start" width="321" trigger="click" v-model="LivePages.emojiAlert"> 
              <div class="emoji-list flex4">
                <div v-for="item in LivePages.emojiList" :key="item.text" class="emoji-item" @click="setEmojiComment(item.text)">
                  <img :src="item.url" alt="">
                </div>
              </div>
              <van-icon slot="reference" name="smile-o" size="22px" class="cur" />
            </el-popover>
          </div>
          <div class="flex2" style="width: 100%">
            <input class="comment-text" v-model="LivePages.commentText" type="input" width="100%" placeholder="我来说几句..." maxlength="50"/>
          </div>
          <div class="flex2 mr ml">
            <el-button :loading="LivePages.sendCommentLoad" type="info" size="small" @click="sendComment">发送</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import { mapState } from "vuex";

import xyLoad from "@/components/xyLoad/index.vue";
import shadeAlert from "@/components/shadeAlert/index.vue";
import VBarrage from "./barrage.vue";
import ttt from "@/components/script/index.vue"

import { emoji } from "./index.js";
import { PlayBack, PlayLike, PlayLikeNum, LivePlayBack } from '@/api/InnovationContest.js';

export default {
  name: "live",
  data() {
    return {
      // !点赞数量轮询
      timerLike: null,
      // !弹窗配置
      shadeConfig: {
        show: false, // 展示隐藏弹窗
        closeIcon: true, // 是否需要关闭按钮
        title: "",
        text: "", // 弹窗内容
        btnType: "", // 按钮形式
        btnList: [],
      },
      // !播放器按钮层
      LivePlayStartState: true,
      // !是否展示播放器模块
      liveLoad: true,
      // !是否初始化成功
      liveInit: false,
      // !直播间用户信息
      LiveUserInfo: {
        assign_id: "",
        avatar: "",
        hash_id: "",
        id: "",
        name: "",
      },
      // 弹幕
      barrageList: [],
      isPause: false,
      // !直播间配置
      LivePages: {
        // @ 是否初始化直播间
        LivePlayer: false,
        // @ 是否正在直播
        isChannelLiving: false,
        // @ 直播封面图
        LiveImage: "",
        // @ 直播间名称
        name: "",
        // @ 直播间人气
        viewNum: "",
        // @ 是否开启弹幕
        barrageState: true,
        // @ 评论列表的总页数
        commentPage: 1,
        // @ 评论列表
        commentList: [],
        // @ 评论内容
        commentText: "",
        // @ 评论按钮loading
        sendCommentLoad: false,
        // @表情list
        emojiList: emoji,
        // @表情弹窗是否展示
        emojiAlert: false,
        // @聊天窗口展示隐藏
        chatShow: document.documentElement.clientWidth > 750 ? true : false,
        // @回访
        LiveAttr: ''
      },
      // !直播点赞数量
      livingNum: 0,
      // !点赞特效
      clickAnimate: false
    };
  },
  mounted() {
    if (!Mudu.Room) {
      this.GetLiveInit();
    } else {
      this.allInit();
    }

    this.GetLiveLikeNum()
  },
  methods: {
    // !播放器按钮层
    LivePlayStart() {
      if(!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来观看直播吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: "已有剑盟账号，前往登录",
              link: "/Login?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
            {
              text: "没有账号，立即注册",
              link:
                "/Register?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
          ],
        };
        return false
      }
      this.LivePlayStartState = false;
      window._player.play();
      PlayBack({id: 0}).then(res => {})
    },
    // !初始化直播组件
    GetLiveInit() {
      Mudu.Init(738798, () => {
        if (!this.LivePages.LivePlayer) {
          this.LivePages.LivePlayer = true;
          this.allInit();
        }
      });
    },
    // !初始化其他组件
    allInit() {
      // @ socket监听
      this.WatchComment();
      // @ 同步用户信息
      Mudu.Room.User.Assign(
        this.Infos.name,
        this.Infos.pictureUrl,
        this.Infos.id,
        (res) => {
          this.LiveUserInfo = res;
        }
      );
      // @直播状态
      this.LivePages.isChannelLiving = Mudu.Room.GetLiveStatus();
      // @直播间浏览量
      this.LivePages.viewNum = Mudu.Room.GetViewNum();
      // @直播间名称
      this.LivePages.name = Mudu.Room.GetName();
      // @直播间窗口封面
      this.LivePages.LiveImage = Mudu.Room.GetLiveImage();
      this.liveLoad = false;
      // @ 获取直播间评论
      this.GetComment();
      // @ 获取直播窗口
      // if (this.LivePages.isChannelLiving) {
      //   this.$nextTick(() => {
      //     this.GetPlayer();
      //   });
      // }
      LivePlayBack({agendaId: 0}).then(res => {
        if (res.status === 1) {
          // @直播间窗口封面
          // this.LivePages.LiveImage = Mudu.Room.GetLiveImage();
          this.LivePages.LiveImage = res.data.coverUrl
          // @获取直播间流地址
          this.LivePages.LiveAttr = res.data.videoUrl
          this.$nextTick(() => {
            this.GetPlayer();
          });
          this.liveLoad = false;
        } else {
          this.$toast(res.message);
        }
      })


      // @ 监听直播流状态
      this.StreamEvent();
    },
    // !初始化直播窗口
    GetPlayer() {
      if (window._player) {
        window._player.remove();
        window._player = null;
      }
      window._player = new Mudu.Player({
        containerId: "J_prismPlayer",
        // isLive: !!Mudu.Room.GetLiveStatus(),
        isLive: false,
        src: this.LivePages.LiveAttr,
        image:  this.LivePages.LiveImage,
        autoplay: false,
        width: this.clientWidth > 750 ? 820.8 : "100%",
        height: this.clientWidth > 750 ? 461.7 : "auto",
        x5Layer: false,
        x5Inline: false,
      });
    },
    // !获取评论
    GetComment() {
      this.LivePages.commentPage = Mudu.Room.Comment.GetPage();
      Mudu.Room.Comment.Get(this.LivePages.commentPage, (res) => {
        res = JSON.parse(res);
        if (res.status === "y") {
          // @ 监听滚动的DOM
          const scrollBox = document.querySelector(
            ".banner-live  .el-scrollbar__wrap"
          );
          this.LivePages.commentList = res.data.comments.reverse();
          this.$nextTick(() => {
            // @ 评论盒子高度
            const COMMENT_BOX_HEIGHT = document.querySelector(
              ".banner-live  .el-scrollbar__view"
            ).clientHeight;
            // @ 聊天窗口高度
            const LIVE_HEIGHT = document.querySelector(
              ".banner-live  .el-scrollbar__wrap"
            ).clientHeight;
            // @ 默认滚动到最底部
            scrollBox.scrollTop = COMMENT_BOX_HEIGHT - LIVE_HEIGHT;

            scrollBox.addEventListener("scroll", () => {
              if (scrollBox.scrollTop == 0) {
                this.GetMoreComment();
              } else {
                return false;
              }
            });
          });
        }
        if (res.status === "n") {
          this.GetComment();
        }
      });
    },
    // !获取更多评论
    GetMoreComment() {
      const AllPage = Math.floor(this.LivePages.commentList.length / 20);
      if (AllPage < this.LivePages.commentPage) {
        Mudu.Room.Comment.Get(this.LivePages.commentPage - AllPage, (res) => {
          res = JSON.parse(res);
          if (res.status === "y") {
            // @ 监听滚动的DOM
            const scrollBox = document.querySelector(
              ".banner-live  .el-scrollbar__wrap"
            );
            // @ 评论合并前盒子高度
            const BEFORE_COMMENT_BOX_HEIGHT = document.querySelector(
              ".banner-live  .el-scrollbar__view"
            ).clientHeight;
            const newComments = res.data.comments;
            newComments.length = 20 - (this.LivePages.commentList.length % 20);
            this.LivePages.commentList = newComments
              .reverse()
              .concat(this.LivePages.commentList);

            this.$nextTick(() => {
              // @ 合并后评论盒子高度
              const AFTER_COMMENT_BOX_HEIGHT = document.querySelector(
                ".banner-live  .el-scrollbar__view"
              ).clientHeight;
              scrollBox.scrollTop =
                AFTER_COMMENT_BOX_HEIGHT - BEFORE_COMMENT_BOX_HEIGHT;
            });
          }
          if (res.status === "n") {
            this.GetMoreComment();
          }
        });
      }
    },
    // !是否开启弹幕
    onInput_BarrageState(checked) {
      this.LivePages.barrageState = checked;
      // this.barrage.pause()
    },
    // !发送文字评论
    sendComment() {
      if (!this.$store.state.token) {
        // this.$toast("请先登录后再来评论吧！");
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来抽奖吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: "已有剑盟账号，前往登录",
              link: "/Login?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
            {
              text: "没有账号，立即注册",
              link:
                "/Register?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
          ],
        };
        return false;
      }

      if (!this.LivePages.commentText.split(" ").join("").length) {
        this.$toast("评论不能为空！");
        return false;
      }
      this.LivePages.sendCommentLoad = true;
      Mudu.Room.Comment.Send(this.LivePages.commentText, (res) => {
        res = JSON.parse(res);
        if (res.status === "y") {
          this.LivePages.commentText = "";
          this.$toast("发送成功");
          this.LivePages.sendCommentLoad = false;
        }
        if (res.status === "n") {
          this.$toast("发送失败，错误码为：" + res.flag);
          this.LivePages.sendCommentLoad = false;
        }
      });
    },
    // !发送表情评论
    setEmojiComment(emoji) {
      if (!this.$store.state.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来抽奖吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: "已有剑盟账号，前往登录",
              link: "/Login?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
            {
              text: "没有账号，立即注册",
              link:
                "/Register?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
          ],
        };
        return false;
      }

      Mudu.Room.Comment.Send(emoji, (res) => {
        this.LivePages.emojiAlert = false;
      });
    },
    // !监听评论变化
    WatchComment() {
      Mudu.MsgBus.On(
        // 事件名，值为Comment.New
        "Comment.New",
        // 事件处理函数，参数为新的评论，类型为object
        (res) => {
          res = JSON.parse(res);
          this.LivePages.commentList.push(res);
          if (res.msg_type === 50) {
          } else if (
            !res.message.includes("em_new_gif") &&
            this.LivePages.barrageState
          ) {
            this.barrageList.push({
              content: res.message,
              direction: "default",
              isSelf: true,
              style: {
                color:
                  this.LiveUserInfo.hash_id == res.visitor_id ? "#03f9e5" : "",
              },
              isJs: false,
            });
          }
          this.$nextTick(() => {
            // @ 监听滚动的DOM
            const scrollBox = document.querySelector(
              ".banner-live  .el-scrollbar__wrap"
            );
            // @ 评论盒子高度
            const COMMENT_BOX_HEIGHT = document.querySelector(
              ".banner-live  .el-scrollbar__view"
            ).clientHeight;
            // @ 聊天窗口高度
            const LIVE_HEIGHT = document.querySelector(
              ".banner-live  .el-scrollbar__wrap"
            ).clientHeight;
            // @ 默认滚动到最底部
            scrollBox.scrollTop = COMMENT_BOX_HEIGHT - LIVE_HEIGHT;
          });
        }
      );
    },
    // !表情转化
    emoji(emoji) {
      if (emoji == "[em_new_gif_laugh&amp;cry]") {
        emoji = "[em_new_gif_laugh&cry]";
      }
      const emo = emoji.split("]")[0].split("_")[
        emoji.split("]")[0].split("_").length - 1
      ];
      return (
        "https://static.mudu.tv/static/emoticons/emoticons-v2-gif/" +
        emo +
        ".gif"
      );
    },
    // !时间戳转化
    dateConversion(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      return Y + M + D + h + m;
    },
    dateState(index, dateline) {
      return (
        !index ||
        this.dateConversion(dateline) !==
          this.dateConversion(
            this.LivePages.commentList[index ? index - 1 : index].dateline
          )
      );
    },
    // !关闭登录指引弹窗
    closeAlertChange(state) {
      this.shadeConfig.show = state;
    },
    // !监听直播流状态
    StreamEvent() {
      Mudu.MsgBus.On(
        // 事件名，值为Room.StreamEvent
        "Room.StreamEvent",
        // 事件处理函数，参数类型为object
        (data) => {
          data = JSON.parse(data);
          if (data.event == 1) {
            if(!this.LivePages.isChannelLiving) {
              this.LivePages.isChannelLiving = true
              this.$nextTick(() => {
                this.GetPlayer();
              });
            }
          } else {
            if(this.LivePages.isChannelLiving) {
              this.LivePlayStartState = true
              this.LivePages.isChannelLiving = false;
            }
          }
        }
      );
    },
    // !直播点赞
    liveLiking() {
      if (!this.$store.state.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: "您还没有登录，<br/>请登录后再来抽奖吧！",
          text: "", // 弹窗内容
          btnType: "link", // 按钮形式
          btnList: [
            {
              text: "已有剑盟账号，前往登录",
              link: "/Login?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
            {
              text: "没有账号，立即注册",
              link:
                "/Register?redirectUrl=" + this.$route.fullPath + "&from=live",
            },
          ],
        };
        return false;
      }
      this.clickAnimate = true
      clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.clickAnimate = false
      }, 100)

      PlayLike().then(res => {
        if (res.status === 1) {
          this.livingNum = res.data.likeNum
        } else {
          this.$toast(res.message)
        }
      })
    },
    // !直播点赞数量轮询
    GetLiveLikeNum() {
      PlayLikeNum().then(res => {
        if(res.status === 1) {
          this.livingNum = res.data.likeNum
        }
      })
      this.timerLike = setInterval(()=> {
        PlayLikeNum().then(res => {
          if(res.status === 1) {
            this.livingNum = res.data.likeNum
          }
        })
      }, 5000)
    }
  },
  beforeDestroy() {
    clearInterval(this.timerLike)
    this.timerLike = null
  },
  computed: {
    ...mapState(["Infos", "clientWidth", "token"]),
  },
  components: {
    xyLoad,
    shadeAlert,
    VBarrage,
    ttt
  },
};
</script>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.emoji-item {
  padding: 5px;
  cursor: pointer;
}
.emoji-item img {
  width: 32px;
  height: 32px;
  transition: all 0.3s;
}
.Live .el-scrollbar__view {
  padding-bottom: 5px;
}

.Live .mudu-big-play-btn {
  display: none;
}

@media (min-width: 769px) {
  .emoji-item:hover {
    background: #f8f8f8;
    border-radius: 3px;
  }
}
@media (max-width: 768px) {
  .Live .el-scrollbar__thumb {
    display: none;
  }
  .Live #J_prismPlayer {
    height: vw(425) !important;
  }
}
</style>


<style lang="scss" scoped>
@import "./index.scss";
</style>