<template>
  <div class="participating-the-judges">
    <h2 v-if="resData.list.length" class="tc innovationContest-subtitle">参赛评委</h2>
    <div v-if="(clientWidth > 750 && resData.list.length < 4) || (clientWidth < 751 && resData.list.length < 2)" class="participating-the-judges-list flex0">
      <div class="participating-the-judges-item" v-for="(item, index) in resData.list" :key="index">
        <img src="@/assets/PC/InnovationContest/judges-icon.png" alt="" class="judges-icon">
        <div class="participating-the-judges-item-banner">
          <img v-if="item.imgUrl" :src="item.imgUrl" alt="" width="100%">
          <img v-else src="@/assets/PC/shop/shop-InfoHead.jpg" alt="" width="100%">
        </div>
        <div class="cur" @click="ShowAlert(item)">
          <div class="participating-the-judges-item-name tc"> {{ item.name }} </div>
          <div class="participating-the-judges-item-position hidden2 tc" v-html="item.position" style="white-space: pre-wrap"></div>
          <div class="participating-the-judges-item-abstract " v-html="item.abstract" style="white-space: pre-wrap"> </div>
        </div>
      </div>
    </div>
    <div v-else class="judges-list" style="position: relative" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
      <Swiper class="judges-swiper" :options="clientWidth > 750 ? swiperOption: swiperOption_m" :auto-play="true" ref="myBotSwiper">
        <swiper-slide v-for="(item, index) in resData.list" :key="index">
          <div class="judges-item">
            <img src="@/assets/PC/InnovationContest/judges-icon.png" alt="" class="judges-icon">
            <div class="participating-the-judges-item-banner">
              <img v-if="item.imgUrl" :src="item.imgUrl" alt="" width="100%">
              <img v-else src="@/assets/PC/shop/shop-InfoHead.jpg" alt="" width="100%">
            </div>
            <div class="cur" :ref="'swiperSlide' + index " @click="ShowAlert(item)">
              <div class="participating-the-judges-item-name tc"> {{ item.name }} </div>
              <div class="participating-the-judges-item-position hidden2 tc" v-html="item.position.replace(/\n/g,'<br/>')" style="white-space: pre-wrap"> </div>
              <div class="participating-the-judges-item-abstract " v-html="item.abstract" style="white-space: pre-wrap"></div>
            </div>
          </div>
        </swiper-slide>
      </Swiper>
      <div class="swiper-button-prev judges-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next judges-button-next" slot="button-next"></div>
    </div>

    <div v-show="IsShowAlert" class="shade flex0">
      <div class="showAlert-box" :class="clientWidth > 750 ? 'showAlert-judges-box' : ''">
        <el-scrollbar>
          <div class="showAlert-content">
            <div class="participating-the-judges-item-banner" style="margin-top: 0">
              <img v-if="ShowAlertContent.imgUrl" :src="ShowAlertContent.imgUrl" alt="" width="100%">
              <img v-else src="@/assets/PC/shop/shop-InfoHead.jpg" alt="" width="100%">
            </div>
            <div class="participating-the-judges-item-name tc"> {{ ShowAlertContent.name }} </div>
            <div class="participating-the-judges-item-position tc" v-html="ShowAlertContent.position" style="white-space: pre-wrap"></div>
            <div class="participating-the-judges-item-abstract" v-html="ShowAlertContent.abstract" style="white-space: pre-wrap;font-size: 16px;"> </div>
          </div>
        </el-scrollbar>
        <div class="show-close">
          <i class="el-icon-circle-close cur" @click="ShowClose"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LiveJuries } from '@/api/InnovationContest.js'

import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.min.css";
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);

export default {
  name: 'ParticipatingTheJudges',
  data () {
    return {
      // !评委数据列表
      resData: {
        list: [],
        resState: false,
        // loading: true,
      },
      swiperOption: {
        slidesPerView: 3,
        // loop: true,
        autoplay: {
          delay: 2500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".judges-button-next",
          prevEl: ".judges-button-prev",
        }
      },
      swiperOption_m: {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 2500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".judges-button-next",
          prevEl: ".judges-button-prev",
        },
      },
      // !详情弹窗
      IsShowAlert: false,
      // !弹窗内容
      ShowAlertContent: {}
    }
  },
  mounted () {
    this.GetInit()
  },
  methods: {
    GetInit() {
      LiveJuries().then(res => {
        if (res.status === 1) {
          this.resData.list = res.data
        } else {
          this.$toast(res.message)
        }
      })
    },
    // !滑入轮播图时，暂停轮播
    on_bot_enter() {
      if (this.clientWidth > 750) {
        this.myBotSwiper.autoplay.stop()
      }
    },
    // !滑出轮播图时，重新开启轮播
    on_bot_leave() {
      if (this.clientWidth > 750) {
        this.myBotSwiper.autoplay.start()
      }
    },
    // !展开弹窗
    ShowAlert(item) {
      this.IsShowAlert = true;
      this.ShowAlertContent = item;
    },
    // !关闭弹窗
    ShowClose() {
      this.IsShowAlert = false
      this.ShowAlertContent = {}
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    },
    myBotSwiper() {
      return this.$refs.myBotSwiper.$swiper
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .swiper-slide,.swiper-wrapper{height:inherit;}
  .participating-the-judges {
    .judges-item {overflow: hidden; margin: 10px; box-shadow: 0 0 10px #E4E4E4; padding-bottom: 15px; position: relative ;border: 1px #fff solid;transition: all 0.3s;border-radius: 5px;height: calc((100% - 20px)); background: #fff }
    .judges-icon { position: absolute; top: 0; left: 0; width: 150px }
    .participating-the-judges-item { margin-left: 20px; width: calc((100% - 40px) / 3); padding-bottom: 15px; position: relative; background: #fff; border: 1px #fff solid; transition: all 0.3s; border-radius: 5px }
    .participating-the-judges-item:first-of-type { margin-left: 0 }
    .participating-the-judges-item-banner { width: 156px; height: 156px; overflow: hidden; border-radius: 78px; margin: 32px auto 15px; box-shadow: 0 0 10px #E4E4E4 }
    .participating-the-judges-item-name { font-size: 20px; font-weight: bold; margin-bottom: 10px; padding: 0 10px;  }
    .participating-the-judges-item-position { color: #666; margin-bottom: 20px; padding: 0 10px; min-height:38px;}
    .participating-the-judges-item-abstract { padding: 0 10px; line-height: 1.3; color: #666; }

    .judges-swiper { padding-bottom: 50px }
    .judges-button-prev { top: inherit; bottom: 0; left: calc((50% - 50px)) }
    .judges-button-next { top: inherit; bottom: 0; right: calc((50% - 50px)) }
    .judges-button-prev,  
    .judges-button-next {
      width: 40px; height: 40px; border-radius: 50%; background: #999; transition: all 0.1s;
    }
    .judges-button-prev::after,.judges-button-next::after { font-size: 15px; color: #Fff; }
  }
  
  @media (min-width: 751px) {
    .participating-the-judges {
      .judges-item:hover,
      .participating-the-judges-item:hover { border: 1px #6B18A8 solid; box-shadow: 0 0 15px #999; }
      .showAlert-judges-box { width: 500px }
    }
  }

  @media (max-width: 750px) {
    .participating-the-judges {
      .participating-the-judges-item { width: calc(100% - 30px); margin: 0 15px; box-shadow: none; background: rgba(255,255,255,0.8) }
      .judges-item { margin: 0 15px; width: calc(100% - 30px); box-shadow: none; background: rgba(255,255,255,0.8) }
      .participating-the-judges-item-banner { margin: vw(30) auto; }
      .participating-the-judges-item-position { min-height: vw(56) }
    }
  }
</style>