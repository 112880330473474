<template>
  <div class="extremeRules">
    <div class="shade flex0" v-show="isShow">
      <div class="content_gz">
        <el-scrollbar>
          <div class="showAlert-contentt ">
            <div role="tabpanel" id="pane-second" aria-labelledby="tab-second" class="el-tab-pane" style="">
              <ul class="el-timeline">
                <h1>积分获取</h1>
                <li class="el-timeline-item">
                  <div class="el-timeline-item__tail"></div>
                  <div class="el-timeline-item__node el-timeline-item__node--normal el-timeline-item__node--">
                  </div>
                  <div class="el-timeline-item__wrapper">
                    <div class="el-timeline-item__content">
                      <div class="inneglectable">
                        <p class="violet inneglectable-title">直播时间： </p>
                        <p class="co-999 dater">2022年1月11日 9:00-16:00</p>
                      </div>
                    </div>
                    <div class="el-timeline-item__timestamp is-bottom"></div>
                  </div>
                </li>
                <li class="el-timeline-item">
                  <div class="el-timeline-item__tail"></div>
                  <div class="el-timeline-item__node el-timeline-item__node--normal el-timeline-item__node--">
                  </div>
                  <div class="el-timeline-item__wrapper">
                    <div class="el-timeline-item__content">
                      <div class="inneglectable">
                        <p class="violet inneglectable-title">决赛作品投票规则：</p>
                        <p class="co-999 dater">
                          注册用户为您喜欢的决赛选手投票，可获得相应积分并参与直播当天积分抽奖，同时您的投票也影响决赛作品名次。
                        </p>
                        <ul class="mt5">
                          <li class="ml15 fs16">
                            • <span class="fw ml10 fs16">报名注册阶段：</span>即日起-1月10日，每天每人3票，单个作品不限被投票次数；
                          </li>
                          <li class="ml15 fs16">
                            • <span class="fw ml10 fs16">直播当天：</span>1月11日，每人12票，单个作品不限被投票次数。
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="el-timeline-item__timestamp is-bottom"></div>
                  </div>
                </li>
                <li class="el-timeline-item">
                  <div class="el-timeline-item__tail"></div>
                  <div class="el-timeline-item__node el-timeline-item__node--normal el-timeline-item__node--">
                  </div>
                  <div class="el-timeline-item__wrapper">
                    <div class="el-timeline-item__content">
                      <div class="inneglectable">
                        <p class="violet inneglectable-title">初赛作品点赞规则：</p>
                        <ul class="mt5">
                          <li class="ml15 fs16">
                            • 注册用户参与初赛作品点赞，为初赛作品的选手加油打气。
                          </li>
                          <li class="ml15 fs16">
                            • 即日起-1月10日，每天每人3个赞，单个作品不限被赞次数。
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="el-timeline-item__timestamp is-bottom"></div>
                  </div>
                </li>
                <li class="el-timeline-item">
                  <div class="el-timeline-item__tail"></div>
                  <div class="el-timeline-item__node el-timeline-item__node--normal el-timeline-item__node--">
                  </div>
                  <div class="el-timeline-item__wrapper">
                    <div class="el-timeline-item__content">
                      <div class="inneglectable">
                        <p class="violet inneglectable-title">积分规则：</p>
                        <ul class="mt5">
                          <li class="ml15 fs16">
                            • 剑盟社区会员：直接登录官网，立即报名观看直播，获得互动积分；
                          </li>
                          <li class="ml15 fs16">
                            • 非剑盟社区会员：注册、报名观看直播，观看选手精彩分享，同时注册剑盟社区获得额外积分兑换好礼。
                          </li>
                        </ul>
                        <table>
                          <tr class="head">
                            <th class="th_w">阶段</th>
                            <th>互动</th>
                            <th class="th_w">积分</th>
                            <th class="th_w">次数</th>
                          </tr>
                          <tr class="ta_body">
                            <td rowspan="6" class="tc">直播前</td>
                            <td class="pl10">非剑盟社区会员-注册大赛</td>
                            <td class="tc">100</td>
                            <td class="tc">1</td>
                          </tr>
                          <tr class="ta_body">
                            <td class="pl10">非剑盟社区会员-注册剑盟社区</td>
                            <td class="tc">100</td>
                            <td class="tc">1</td>
                          </tr>
                          <tr class="ta_body">
                            <td class="pl10">报名观看直播（剑盟会员+非剑盟会员）</td>
                            <td class="tc">200</td>
                            <td class="tc">1</td>
                          </tr>
                          <tr class="ta_body">
                            <td class="pl10">邀3个新用户注册</td>
                            <td class="tc">600</td>
                            <td class="tc">不限定</td>
                          </tr>
                          <tr class="ta_body">
                            <td class="pl10">决赛作品投票</td>
                            <td class="tc">20/次</td>
                            <td class="tc">3次/每天</td>
                          </tr>
                          <tr class="ta_body">
                            <td class="pl10">剑盟社区积分</td>
                            <td class="tc">大赛期间积分双倍</td>
                            <td class="tc">不限定</td>
                          </tr>
                          <tr class="ta_body" style="background: #EEEEEE;">
                            <td rowspan="2" class="tc">直播当天</td>
                            <td class="pl10">调查问卷填写</td>
                            <td class="tc">200</td>
                            <td class="tc">1</td>
                          </tr>
                          <tr class="ta_body" style="background: #EEEEEE;">
                            <td class="pl10">决赛作品投票</td>
                            <td class="tc">30/票</td>
                            <td class="tc">12票</td>
                          </tr>
                          <tr class="ta_body">
                            <td rowspan="6" class="tc">直播结束</td>
                            <td class="pl10">直播回放观看</td>
                            <td class="tc">300</td>
                            <td class="tc">1</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="el-timeline-item__timestamp is-bottom"></div>
                  </div>
                </li>
                <li class="el-timeline-item el-timeline-item_1">
                  <div class="el-timeline-item__tail"></div>
                  <div class="el-timeline-item__node el-timeline-item__node--normal el-timeline-item__node--">
                  </div>
                  <div class="el-timeline-item__wrapper">
                    <div class="el-timeline-item__content">
                      <div class="inneglectable">
                        <p class="violet inneglectable-title">积分抽奖奖品： </p>
                        <p class="co-999 dater">1月11日，直播当天开放抽奖互动，更多大奖等着你！</p>
                        <div class="Favors flex9">
                          <div class="Favor-item">
                            <div class="Favor-item-img flex">
                              <img src="@/assets/PC/InnovationContest/gift1.png" alt="" width="100%">
                            </div>
                            <div class="Favor-item-description flex1 tc">咖啡券</div>
                            <div class="Favor-item-titles">
                              <img src="@/assets/PC/InnovationContest/Rectangle.png" alt="" width="100%">
                              <div class="Favor-item-title">
                                <p class="co-fff">礼品一</p>
                              </div>
                            </div>
                          </div>
                            <div class="Favor-item">
                            <div class="Favor-item-img flex">
                              <img src="@/assets/PC/InnovationContest/gift2.png" alt="" width="100%">
                            </div>
                            <div class="Favor-item-description flex1 tc">U型枕</div>
                            <div class="Favor-item-titles">
                              <img src="@/assets/PC/InnovationContest/Rectangle.png" alt="" width="100%">
                              <div class="Favor-item-title">
                                <p class="co-fff">礼品二</p>
                              </div>
                            </div>
                          </div>
                          <div class="Favor-item">
                            <div class="Favor-item-img flex">
                              <img src="@/assets/PC/InnovationContest/gift3.png" alt="" width="100%">
                            </div>
                            <div class="Favor-item-description flex1 tc">华为耳机</div>
                            <div class="Favor-item-titles">
                              <img src="@/assets/PC/InnovationContest/Rectangle.png" alt="" width="100%">
                              <div class="Favor-item-title">
                                <p class="co-fff">礼品三</p>
                              </div>
                            </div>
                          </div>
                          <div class="Favor-item">
                            <div class="Favor-item-img flex">
                              <img src="@/assets/PC/InnovationContest/gift4.png" alt="" width="100%">
                            </div>
                            <div class="Favor-item-description flex1 tc">Surface 笔记本电脑</div>
                            <div class="Favor-item-titles">
                              <img src="@/assets/PC/InnovationContest/Rectangle.png" alt="" width="100%">
                              <div class="Favor-item-title">
                                <p class="co-fff">礼品四</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul class="mt5 tc">
                          <li class="ml15 fs16">
                            如有任何疑问可联系大赛组委会邮箱： No-Reply@avevacommunity.com
                          </li>
                          <li class="ml15 fs16 co-999" style="font-size: 12px;">
                            * 本次大赛最终解释权归大赛组委会所有。
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <div class="el-timeline-item__tail"></div>
                <div style="margin-bottom:50px"></div>
              </ul>
            </div>
          </div>
        </el-scrollbar>
        <div class="show-close" @click="close">
          <i class="el-icon-circle-close cur"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'extremeRules',
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    close() {
      this.$emit('change',false )
    }
  },
  props: {
    isShow: {
      type: Boolean
    }
  },
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .extremeRules {
    .content_gz{max-width: 780px; padding: 20px 0px 20px 20px;background: #fff;position: relative;}
    .el-timeline-item__content{padding-top: 3px;}
    .inneglectable-title{font-size: 16px; font-weight: bold;}
    .dater{ font-size: 16px; margin-top: 6px;}
    .el-timeline-item{padding-bottom: 10px;}
    .fs16{font-size: 16px; line-height: 30px;}
    .fw{font-weight: bold;}
    .head{background: #E0E0E0;height: 41px; text-align: center; }
    .ta_body{background: #F8F8F8;height: 41px;}
    th{padding: 0 25px 0 25px; font-size: 16px;}
    td{font-size: 16px;}
    table{width: 100%;margin-top: 7px;}
    .pl10{padding-left: 10px;}
    .showAlert-contentt { max-height: 81.5vh;padding: 0 20px 1px 0;}
    h1{text-align: center; margin: 40px auto;}
    .Favors{margin-top: 10px;}
    .Favor-item {position: relative; overflow: hidden; background-color: #F4ECF8; border-radius: 5px;width: calc((100% - 20px) / 4);}
    .Favor-item-titles {width: 100%; height: 83px; position: absolute; left: 0; top: 0;}
    .Favor-item-title { position: absolute; left: 14px; top: 6px;}
    .Favor-item-description { font-size: 15px; line-height: 24px; padding: 7px 10px; color: #828282;}
    .el-timeline-item_1{padding-bottom: 1px;}
    .saded{width: 100%;
      height: 102vh;
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      z-index: 999999;}
    // .shade{display: none;}
    // .Competition-rules:hover +.shade{display: block;}
  }
  

  @media (max-width: 750px) {
     // !大赛规则
  .extremeRules {
    .content_gz{max-width: calc(100% - 30px);
    padding: 4vw 0px 4vw 4vw;
    margin: 0 15px;
    background: #fff;
    border-radius: 10px;}
    .el-timeline-item__content{padding-top: 1px;}
    .inneglectable-title{font-size: 14px;}
    .dater{ font-size: 14px; margin-top: vw(12);}
    .el-timeline-item{padding-bottom: vw(20);}
    .fs16{font-size: 14px; line-height: inherit; margin-top: 3px;}
    .fw{font-weight: bold;}
    .head{height: vw(82);}
    .ta_body{height:vw(82);}
    th{padding: inherit; font-size: 14px; }
    .th_w{width: vw(130);}
    td{font-size: vw(20);}
    table{margin-top: vw(14);}
    .pl10{padding-left: vw(20);}
    .showAlert-contentt { max-height: 70vh; padding: 0 vw(30) vw(2) 0;}
    h1{text-align: center; margin: vw(60) auto; font-size: 20px;}
    .Favors{margin-top: vw(20); flex-wrap: wrap; justify-content: start;}
    .Favor-item {width: calc((100% - 10px) / 2);margin-right: 5px; margin-bottom: 10px;}
    .Favor-item-titles {width: 100%; height: inherit; position: absolute; left: 0; top: 0px;}
    .Favor-item-title { position: absolute; left: vw(20); top: vw(9);}
    .Favor-item-description { font-size: 12px; line-height: inherit; padding:5px ;}
    .co-fff{font-size: vw(24);}
    // .shade{display: none;}
    // .Competition-rules:hover +.shade{display: block;}
    }
  }
</style>
