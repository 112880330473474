<template>
  <div class="EntryWorksSwiper" style="position: relative">
    <Swiper class="entry-swiper" :options="clientWidth > 750 ? swiperOption: swiperOption_m" :auto-play="true">
      <swiper-slide v-for="(item, index) in configSwiper.listData" :key="index">
        <div class="works-swiper-item">
          <div class="works-content-module1">
            <div class="works-item-headUrl" style="position: relative">
              <img src="@/assets/PC/InnovationContest/Picture-frame.png" class="works-item-headUrl-frame">
              <div class="works-item-heat-box">
                <div class="works-item-heat-text tc">
                  <van-icon name="fire" />
                  {{ item.hotNum }}
                </div>
              </div>
              <div class="works-item-headUrl" style="overflow: hidden">
                <img :src="item.imgUrl" width="100%" />
              </div>
            </div>
          </div>
          <div class="works-content flex">
            <div class="works-content-l">
              <div class="works-icon-line"></div>
            </div>
            <div class="works-content-r cur" @click="ShowAlert(item)">
              <div class="works-title">{{ item.title }}</div>
              <div v-if="item.userName" class="works-company co-666 mb">
                <b v-if="configSwiper.activeType === 0">参赛人：</b>
                <b v-else>项目人：</b>
                {{ item.userName }}
              </div>
              <div class="works-company co-666 mb">
                <b>所属公司：</b>
                {{ item.company }}
              </div>
              <div class="works-abstract co-666 hidden3">
                <b>作品简介：</b>
                {{ item.abstract }}
              </div>
            </div>
          </div>
          <!-- <div class="works-like-box" v-if="configSwiper.activeType == 0">
            <div class="works-like-item cur">
              <img src="@/assets/PC/InnovationContest/unVoted.png" width="100%" @click="IsVoted(item.id, index, configSwiper.indexCount)"/>
            </div>
          </div> -->

          <!-- <div class="works-like-box" v-if="configSwiper.activeType == 1">
            <div class="works-like-item cur" @click="IsLiked(item.id, item.isLike, index)">
              <img src="@/assets/PC/InnovationContest/Like.png" width="100%" @click="IsLiked(item.id, index, configSwiper.indexCount)"/>
            </div>
          </div> -->
        </div>
      </swiper-slide> 
    </Swiper>
    <div class="swiper-button-prev works-prev" :class="configSwiper.prevBtn" slot="button-prev"></div>
    <div class="swiper-button-next works-next" :class="configSwiper.nextBtn" slot="button-next"></div>
    
    <div v-show="IsShowAlert" class="shade flex0">
      <div class="showAlert-box">
        <el-scrollbar>
          <div class="showAlert-content">
            <div v-if="configSwiper.activeType === 0">
              <div class="works-content-module1">
                <div style="position: relative">
                  <div class="works-item-logo" style="overflow: hidden">
                    <img :src="ShowAlertContent.userImgUrl" width="100%" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="configSwiper.activeType === 1">
              <img :src="ShowAlertContent.imgUrl" alt="" width="100%">
            </div>
            <div class="flex mt15" >
              <div v-if="configSwiper.activeType === 0" class="works-content-l">
                <div class="works-icon-line"></div>
              </div>
              <div class="works-content-r">
                <div class="works-title" v-if="configSwiper.activeType === 0">{{ ShowAlertContent.title }}</div>
                <div v-if="ShowAlertContent.userName && configSwiper.activeType === 1" class="works-company co-666 mb">
                  <b>项目人：</b>
                  {{ ShowAlertContent.userName }}
                </div>
                <div class="works-company co-666 mb">
                  <b>所属公司：</b>
                  {{ ShowAlertContent.company }}
                </div>
                <div class="works-abstract co-666">
                  <b>作品简介：</b>
                  {{ ShowAlertContent.abstract }}
                </div>
                <div class="works-contact-me co-voi flex2 mt10">
                  <span v-if="ShowAlertContent.url" class="cur co-voi" @click="checkWork(ShowAlertContent.url,ShowAlertContent.id)">
                    查看完整作品 <i class="el-icon-arrow-right"></i>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="configSwiper.activeType" class="works-item-call tc"  :style="configSwiper.activeType ? '' : 'border-bottom: 2px dashed #CD9EDE'">
              <a :href="'mailto:' + ShowAlertContent.mailto" class="cur co-fff" @click="GetData(configSwiper.id)">联系我<i class="el-icon-arrow-right"></i></a>
            </div>
            <div v-else class="works-item-call tc"  :style="configSwiper.activeType ? '' : 'border-bottom: 2px dashed #CD9EDE'">
              <a :href="'mailto:' + ShowAlertContent.userMailto" class="cur co-fff" @click="GetData(configSwiper.id)">联系我<i class="el-icon-arrow-right"></i></a>
            </div>
            
            <div v-if="configSwiper.activeType === 0">
              <div class="works-item-userName tc" style="margin-top:10px">{{ ShowAlertContent.userName }}</div>
              <div class="works-item-userPosition tc">{{ ShowAlertContent.userPosition }}</div>
              <div class="works-item-userCompany tc co-666">{{ ShowAlertContent.userCompany }}</div>
              <div class="works-item-userAbstract co-666">{{ ShowAlertContent.userAbstract }}</div>
            </div>
          </div>
        </el-scrollbar>
        <div class="show-close">
          <i class="el-icon-circle-close cur" @click="ShowClose"></i>
        </div>
      </div>
    </div>
    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { debounce, GetShareData } from "@/utils/common"
// import { AddLikeEntries, Like }  from '@/api/InnovationContest'
import shadeAlert from '@/components/shadeAlert/index.vue'

import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.min.css";
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);

export default {
  name: 'EntryWorksSwiper',
  data () {
    return {
      // !数据是否传入
      IsData: false,
      noSwiping: true,
      effect: "creative",
      swiperOption: {
        slidesPerView: 3,
        observer:true,
        autoplay: {
          delay: 2500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".works-next",
          prevEl: ".works-prev",
        }
      },
      swiperOption_m: {
        slidesPerView: 1,
        observer:true,
        autoplay: {
          delay: 2500,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".works-next",
          prevEl: ".works-prev",
        },
      },
      // !弹窗展示内容
      ShowAlertContent: {},
      // !弹窗是否展示
      IsShowAlert: false,
      // !弹窗配置
      shadeConfig: {
        show: false, // 展示隐藏弹窗
        closeIcon: true, // 是否需要关闭按钮
        title: '',
        text: '', // 弹窗内容
        btnType: '', // 按钮形式
        btnList: []
      }
    }
  },
  props: ['configSwiper'],
  created() {
    if(this.clientWidth < 751) {
      this.swiperOption_m.navigation.prevEl ='.' + this.configSwiper.prevBtn
      this.swiperOption_m.navigation.nextEl ='.' + this.configSwiper.nextBtn
      this.IsData = true
    }
  },
  methods:{
    // !展开弹窗
    ShowAlert(item) {
      this.IsShowAlert = true;
      this.ShowAlertContent = item;
    },
    // !关闭弹窗
    ShowClose() {
      this.IsShowAlert = false
      this.ShowAlertContent = {}
    },
    // // !投票
    // IsVoted(id, index, indexCount) {
    //   if (!this.token) {
    //     this.shadeConfig = {
    //       show: true, // 展示隐藏弹窗
    //       closeIcon: true, // 是否需要关闭按钮
    //       title: '您还没有登录，<br/>请登录后再来投票吧！',
    //       text: '', // 弹窗内容
    //       btnType: 'link', // 按钮形式
    //       btnList: [
    //         {text:'已有剑盟账号，前往登录', link: '/Login?redirectUrl=' + this.$route.fullPath  + '&from=live'},
    //         {text:'没有账号，立即注册', link:'/Register?redirectUrl=' + this.$route.fullPath  + '&from=live'}
    //       ]
    //     }
    //     return false
    //   }
    //   debounce(() => {
    //     AddLikeEntries({id: id}).then(res => {
    //       this.$toast(res.message)
    //       if(res.status === 1) {
    //         this.$emit('listDataChange',{index, indexCount})
    //       }
    //     })
    //   }, 1000)
    // },
    // // !点赞
    // IsLiked(id, index, indexCount) {
    //   if (!this.token) {
    //     this.shadeConfig = {
    //       show: true, // 展示隐藏弹窗
    //       closeIcon: true, // 是否需要关闭按钮
    //       title: '您还没有登录，<br/>请登录后再来点赞吧！',
    //       text: '', // 弹窗内容
    //       btnType: 'link', // 按钮形式
    //       btnList: [
    //         {text:'已有剑盟账号，前往登录', link: '/Login?redirectUrl=' + this.$route.fullPath  + '&from=live'},
    //         {text:'没有账号，立即注册', link:'/Register?redirectUrl=' + this.$route.fullPath  + '&from=live'}
    //       ]
    //     }
    //     return false
    //   }
    //   debounce(() => {
    //     Like({id: id}).then(res => {
    //       this.$toast(res.message)
    //       if(res.status === 1) {
    //         this.$emit('listDataChange',{index, indexCount})
    //       }
    //     })
    //   }, 1000)
    // },
    // !查看完整作品
    checkWork(url, id) {
      if(!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: '您还没有登录，<br/>请登录后再来查看作品！',
          text: '', // 弹窗内容
          btnType: 'link', // 按钮形式
          btnList: [
            {text:'已有剑盟账号，前往登录', link: '/Login?redirectUrl=' + this.$route.fullPath  + '&from=live'},
            {text:'没有账号，立即注册', link:'/Register?redirectUrl=' + this.$route.fullPath  + '&from=live'}
          ]
        }
        return false
      }
      if(this.Infos.type == 1) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: '您还不是剑盟会员，<br/>请先申请成为剑盟会员吧！',
          text: '', // 弹窗内容
          btnType: 1, // 按钮形式
          btnList: [],
          btnText: '点击成为剑盟会员'
        }
        return false
      }
      GetShareData(2, "EntryWorksSwiper", id)
      if(this.clientWidth > 750) {
        window.open(url)
      } else {
        window.location.href = url
      }
    },
    // !关闭登录指引弹窗
    closeAlertChange(state){
      this.shadeConfig.show = state
    },
    // !联系我 的数据统计
    GetData(id) {
      GetShareData(3, "EntryWorksSwiper", id)
    }
  },
  computed: {
    ...mapState(['clientWidth','Infos', 'token'])
  },
  components: {
    Swiper,
    SwiperSlide,
    shadeAlert
  }
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .entry-swiper{ padding: 0 0 70px; }
  .works-swiper-item { height: 100%; position: relative; background: #FFF; margin: 10px; border: 1px #fff solid; transition: all 0.3s; border-radius: 5px }
  .works-swiper-item:hover { border: 1px #6B18A8 solid; box-shadow: 0 0 15px #999; }
  .works-prev { top: inherit; bottom: 0px; left: calc((50% - 50px)) }
  .works-next { top: inherit; bottom: 0px; right: calc((50% - 50px)) }
  .works-prev,
  .works-next {
    width: 40px; height: 40px; border-radius: 50%; background: #999; transition: all 0.1s;
  }
  .works-prev::after,.works-next::after { font-size: 15px; color: #Fff; }
  @media (max-width: 750px) {
    .EntryWorksSwiper {
      padding-bottom: vw(100);
      .entry-swiper{ padding: 0 0 30px; }
      .EntryWorks-item { padding-bottom: 35px;}
      .works-swiper-item1 {padding-bottom: 80px;}
      
      }
  }
</style>