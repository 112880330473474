<template>
  <div v-if="questionnaireState" class="questionnaire shade flex0">
    <div class="questionnaire-content">
      <i class="el-icon-circle-close close-alert co-fff cur" @click="closeAlert" style="z-index: 1"></i>
      <div class="questionnaire-content-banner">
        <img src="@/assets/PC/InnovationContest/questionnaireBanner.png" alt="" width="100%" />
      </div>
      <div class="questionnaire-form">
        <el-scrollbar style="height:100%;" ref="scrollBox">
          <div class="questionnaire-form-content">
            <div class="xy-form-item">
              <div class="xy-form-label"><span class="xy-icon-ic"> * </span>1.请问您是业主还是工程公司？（单选）</div>
              <div class="xy-form-content">
                <van-radio-group v-model="formList.custom1">
                  <van-radio name="业主" checked-color="#6b18a8" class="xy-form-content-item" icon-size="18px">业主</van-radio>
                  <van-radio name="工程公司 / 设计院 / EPC" checked-color="#6b18a8" class="xy-form-content-item" icon-size="18px">工程公司 / 设计院 / EPC</van-radio>
                </van-radio-group>
              </div>
              <div class="xy-form-error"></div>
            </div>
            <div class="xy-form-item">
              <div class="xy-form-label"><span class="xy-icon-ic"> * </span> 2.如果您是业主，可否分享贵司使用的设计院 / EPC 是哪家？（填空）</div>
              <div class="xy-form-content">
                <el-input type="index" v-model="formList.custom2" placeholder="请输入内容"></el-input>
              </div>
              <div class="xy-form-error"></div>
            </div>
            <div class="xy-form-item">
              <div class="xy-form-label"><span class="xy-icon-ic"> * </span>3.观看了今天的创新大赛决赛，您希望与哪个作品的方案进行商务合作？（多选）</div>
              <div class="xy-form-content">
                <van-checkbox-group v-model="formList.custom3">
                  <van-checkbox v-for="item in checkboxList" :key="item.id" :name="item.text" checked-color="#6b18a8" icon-size="18px" class="xy-form-content-item" shape="square">
                    {{ item.text }}
                  </van-checkbox>
                </van-checkbox-group>
              </div>
              <div class="xy-form-error"></div>
            </div>
            <div class="xy-form-item">
              <div class="xy-form-label"><span class="xy-icon-ic"> * </span>4.最近您有明确的数字化项目吗？预计规模如何？（单选）</div>
              <div class="xy-form-content">
                <van-radio-group v-model="formList.custom4">
                  <van-radio name="50万以下" checked-color="#6b18a8" icon-size="18px" class="xy-form-content-item">50万以下</van-radio>
                  <van-radio name="50-100万" checked-color="#6b18a8" icon-size="18px" class="xy-form-content-item">50-100万</van-radio>
                  <van-radio name="100万以上" checked-color="#6b18a8" icon-size="18px" class="xy-form-content-item">100万以上</van-radio>
                  <van-radio name="其他" checked-color="#6b18a8" icon-size="18px" class="xy-form-content-item">其他</van-radio>
                </van-radio-group>
              </div>
              <div class="xy-form-error"></div>
            </div>
            <div class="xy-form-item">
              <div class="xy-form-label"><span class="xy-icon-ic"> * </span>5.您对本次大赛直播内容满意度</div>
              <div class="xy-form-content">
                <van-rate v-model="formList.custom5" color="#6b18a8"/>
              </div>
              <div class="xy-form-error"></div>
            </div>
            <div class="xy-form-item">
              <div class="xy-form-label"><span class="xy-icon-ic"> * </span>6.您对本次大赛直播形式满意度</div>
              <div class="xy-form-content">
                <van-rate v-model="formList.custom6" color="#6b18a8"/>
              </div>
              <div class="xy-form-error"></div>
            </div>
            <div class="xy-form-item">
              <div class="xy-form-label"><span class="xy-icon-ic"> * </span>7.您对本次大赛直播效果满意度</div>
              <div class="xy-form-content">
                <van-rate v-model="formList.custom7" color="#6b18a8" />
              </div>
              <div class="xy-form-error"></div>
            </div>
            <div class="xy-form-item">
              <div class="xy-form-label">8.我们希望能继续优化为您提供更好的内容和体验，可否请您留下一些宝贵建议？</div>
              <div class="xy-form-content">
                <el-input type="textarea" v-model="formList.custom8" placeholder="请输入您的建议" maxlength="200" show-word-limit></el-input>
              </div>
              <div class="xy-form-error"></div>
            </div>
            <div class="xy-form-item tc">
              <el-button :loading="submitBtnLoad" type="info" style="width: 150px" @click="submitForm">提 交</el-button>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { FeedBack, FeedBackControl } from "@/api/InnovationContest.js";
import { ApiToken } from '@/utils/ApiToken'
export default {
  name: "questionnaire",
  props: ["questionnaireState"],
  data() {
    return {
      // !表单内容
      formList: {
        custom1: "",
        custom2: "",
        custom3: [],
        custom4: "",
        custom5: null,
        custom6: null,
        custom7: null,
        custom8: "",
      },
      checkboxList: [
        {
          id: 10101,
          name: "1",
          text: "PipingBox-基于 AVEVA PDMS 和 E3D 自主开发工具管理平台",
        },
        { id: 10102, name: "2", text: "基于云平台的装置全生命周期数字化交付" },
        {
          id: 10103,
          name: "3",
          text: "特大型市政污水处理厂生产综合监控系统应用技术研究及工程示范技术方案",
        },
        {
          id: 10104,
          name: "4",
          text: "AVEVA System Platform 和 OSIsoft 在天然气输配控制的人工智能化系统中的应用",
        },
        { id: 10105, name: "5", text: "火力发电机组无人值守解决方案" },
        {
          id: 10106,
          name: "6",
          text: "AVEVA System Platform 在青草沙原水厂远程泵站的应用",
        },
        {
          id: 10107,
          name: "7",
          text: "AVEVA E3D 设备自动建模及模块化生成管道材料报告工具",
        },
        {
          id: 10108,
          name: "8",
          text: "AVEVA 在造船电气一体化设计中的开发应用",
        },
        {
          id: 10109,
          name: "9",
          text: "水龙头（2V2多变量模型）AVEVA APC Demo 应用",
        },
        {
          id: 10110,
          name: "10",
          text: "基于 AVEVA AIM 的工厂管道焊接管理系统",
        },
        { id: 10111, name: "11", text: "山东某集团数字化交付项目" },
        {
          id: 10112,
          name: "12",
          text: "AVEVA 剑维软件在白银有色集团“三化”数字化转型中的应用与规划方案",
        },
      ],
      // !提交按钮loading
      submitBtnLoad: false,
      // !弹出状态
      popup: {
        type: "",
        popup: false,
      },
    };
  },
  // mounted() {
  //   if(localStorage.getItem(ApiToken.token)) {
  //     this.showAlertState();
  //   }
  // },
  methods: {
    // showAlertState() {
    //   this.timer = setInterval(() => {
    //     FeedBackControl({ type: this.popup.type }).then((res) => {
    //       if (res.status === 1) {
    //         this.popup = res.data;
    //         if (res.data.type === -1) {
    //           clearInterval(this.timer);
    //         } else if (res.data.popup) {
    //           if (!this.questionnaireState) {
    //             this.$emit("changeQuestionnaireState", true);
    //           }
    //         }
    //       }
    //     });
    //   }, 5000);
    // },
    //! 关闭问卷弹窗
    closeAlert() {
      this.formList = {
        custom1: "",
        custom2: "",
        custom3: [],
        custom4: "",
        custom5: null,
        custom6: null,
        custom7: null,
        custom8: "",
      };
      this.$emit("changeQuestionnaireState", false);
    },
    // !提交表单
    submitForm() {
      if (
        !this.formList.custom1 ||
        !this.formList.custom2.split(" ").join("").length ||
        !this.formList.custom3.length ||
        !this.formList.custom4 ||
        !this.formList.custom5 ||
        !this.formList.custom6 ||
        !this.formList.custom7
      ) {
        this.$toast("请检查表单是否有必填项（必选项）未填写（选择）！");
        return false;
      }
      const list = JSON.parse(JSON.stringify(this.formList));
      list.custom3 = list.custom3.join(",");
      list.custom5 = list.custom5 + "";
      list.custom6 = list.custom6 + "";
      list.custom7 = list.custom7 + "";
      FeedBack(list).then((res) => {
        if (res.status === 1) {
          this.$toast.success("提交成功");
          this.closeAlert();
        } else {
          this.$toast(res.message);
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.questionnaire .el-scrollbar__wrap {
  overflow-x: hidden;
}
.questionnaire .el-textarea__inner {
  min-height: 110px !important;
}
@media (max-width: 768px) {
  .questionnaire .el-scrollbar__bar {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.questionnaire {
  .questionnaire-content {
    max-width: 550px;
    background: #fff;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
  }
  .questionnaire-form {
    height: 400px;
  }
  .questionnaire-form-content {
    padding: 15px;
  }
  .xy-form-content {
    padding-left: 15px;
  }
  .xy-form-label {
    margin-bottom: 10px;
  }
  .xy-form-content-item {
    margin-top: 5px;
  }
  .xy-form-error {
    height: 24px;
    line-height: 24px;
    color: #ff0000;
  }
}

@media (max-width: 768px) {
  .questionnaire {
    .questionnaire-content {
      width: calc(100% - 30px);
      box-shadow: none;
    }
  }
}
</style>