<template>
  <div class="drawModule">
    <div class="drawModule-banner">
      <img src="@/assets/PC/InnovationContest/draw-banner.png" alt="" width="100%">
      <div class="drawModule-fixed-banner">
        <div class="drawModule-fixed-banner-title tc">
          <img src="@/assets/PC/InnovationContest/draw-title.png" alt="" width="50%">
          <p class="violet mt10">Surface笔记本电脑等你拿！</p>
        </div>
      </div>
      <div class="drawModule-content tc">
        <img src="@/assets/PC/InnovationContest/draw-lucky.png" alt="" width="60%">
        <div class="drawModule-content-draw-gift flex0">
          <img src="@/assets/PC/InnovationContest/draw-gift.png" alt="" width="51%" 
            :style="{
              transform: 'rotate(' + rotateAngle + 'deg)',
              transition: rotateTransition
            }"
          >
        </div>
        <div class="drawModule-content-draw-start">
          <img class="cur" src="@/assets/PC/InnovationContest/draw-start.png" alt="" width="100%" @click="drawStart">
        </div>

      </div>
      <div class="drawModule-footer tc">
        <h1>您还有<b class="violet">{{ drawNumCopy.num }}</b>次机会 <b v-if="token" class="violet">（当前积分：{{drawNumCopy.money}}）</b></h1>
        <p>提示：抽一次，消耗<span>100积分</span></p>
        <p class="co-999" style="font-size: 12px">*本次活动最终解释权归大赛组委会所有</p>
      </div>
    </div>

    <div v-show="drawGiftState" class="drawModule-gift-shade flex0">
      <div class="flex0" style="width: 90%">
        <div class="draw-gift-bg">
          <i class="el-icon-circle-close close-alert co-fff cur" @click="closeAlert" style="z-index: 2"></i>
          <!-- <img class="draw-box-bg" src="@/assets/PC/home/activity.png" alt="" width="100%" height="300"> -->
          <div class="drawGift-content tc">
            <div class="drawGift-state" v-if="giftDetail.index % 2 !== 1">
              <img :src=" require('@/assets/PC/InnovationContest/drawGift'+ giftDetail.index + '.png')" alt="">
              <p :class="clientWidth > 768 ? 'mb15': 'mb10'" v-if="Infos.type == 1">恭喜您获得 {{ giftDetail.name }}！活动结束后，工作人员会与您取得联系。您也可以在【我的积分】查看中奖礼品。</p>
              <p :class="clientWidth > 768 ? 'mb15': 'mb10'" v-else>恭喜您获得 {{ giftDetail.name }}！活动结束后，工作人员会与您取得联系。您也可以在【个人中心】查看中奖礼品。</p>
            </div>
            <p class="drawGift-state-null" v-else>很遗憾，您未抽中奖品！<br>关注剑维软件官微，获取行业更多资讯</p>

            <el-button type="info" class="xy-btn" @click="closeAlert" style="width: 120px">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DrawNum, LuckDraw } from "@/api/InnovationContest.js";

export default {
  name: "drawModule",
  data() {
    return {
      // !是否开始抽奖
      drawStartState: false,
      // !转动角度
      rotateAngle: 0,
      // !获奖弹窗状态
      drawGiftState: false,
      // !控制转动动画
      rotateTransition: "",
      // !奖品数据
      giftDetail: {
        // $ 奖品索引
        index: 1,
        // $ 奖品名称
        name: "",
      },
    };
  },
  props: ["drawNumCopy"],
  methods: {
    // !读取抽奖次数
    drawNumInit() {
      if (this.token) {
        DrawNum().then((res) => {
          if (res.status === 1) {
            this.$emit("changeDrawNum", res.data);
          } else {
            this.$toast(res.message);
          }
        });
      }
    },

    drawStart() {
      if (!this.drawStartState) {
        // if (this.drawNumCopy.num == 0) {
        //   this.$toast("您的抽奖次数已用完！");
        //   return false;
        // }

        this.drawStartState = true;
        this.rotateTransition = "transform 4s ease-in-out";
        this.getLotteryPrize();
      }
    },
    // !获取奖品
    getLotteryPrize() {
      LuckDraw().then((res) => {
        if (res.status === 1) {
          this.drawNumInit();
          this.giftDetail = res.data;
          this.rotate(this.giftDetail);
        } else {
          this.drawStartState = false;
          this.$toast(res.message);
        }
      });
    },
    // !转盘转动角度计算
    rotate(prize) {
      // 附加多转几圈，2-3
      const randCircle = 7;
      // 默认为 5s
      const duringTime = 5;
      // 旋转角度
      switch ("奖品" + prize.index) {
        case "奖品1":
          this.rotateAngle = randCircle * 360 + 337.5;
          break;
        case "奖品2":
          this.rotateAngle = randCircle * 360 + 292.5;
          break;
        case "奖品3":
          this.rotateAngle = randCircle * 360 + 247.5;
          break;
        case "奖品4":
          this.rotateAngle = randCircle * 360 + 202.5;
          break;
        case "奖品5":
          this.rotateAngle = randCircle * 360 + 157.5;
          break;
        case "奖品6":
          this.rotateAngle = randCircle * 360 + 112.5;
          break;
        case "奖品7":
          this.rotateAngle = randCircle * 360 + 67.5;
          break;
        case "奖品8":
          this.rotateAngle = randCircle * 360 + 22.5;
          break;
      }
      setTimeout(() => {
        this.drawGiftState = true;
        this.rotateTransition = "transform 0.01s ease-in-out";
        this.rotateAngle = 0;
      }, duringTime * 1000 + 10);
    },
    // !关闭获奖弹窗
    closeAlert() {
      this.drawStartState = false;
      this.drawGiftState = false;
      this.rotateAngle = 0;
    },
  },
  computed: {
    ...mapState(["token", "Infos", "clientWidth"]),
  },
};
</script>

<style lang="scss" scoped>
.drawModule {
  max-width: 500px;
  position: relative;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  .drawModule-fixed-banner {
    position: relative;
  }
  .drawModule-fixed-banner-title {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 20px;
  }
  .drawModule-fixed-banner-title p {
    font-size: 16px;
  }

  .drawModule-content {
    position: relative;
  }
  .drawModule-content-draw-gift,
  .drawModule-content-draw-start {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .drawModule-content-draw-start {
    width: 15%;
    z-index: 1;
  }
  .drawModule-content-draw-start img {
    transform: translateY(85%);
  }

  .drawModule-gift-shade {
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }
  .drawGift-content {
    width: 100%;
    padding: 15% 15px 15px 15px;
  }

  .drawModule-footer {
    padding-bottom: 20px;
  }
  .drawModule-footer h1,
  .drawModule-footer h1 b {
    padding: 10px 0;
    font-size: 20px;
  }
  .drawModule-footer p {
    line-height: 1.5;
  }
  .drawGift-state img {
    width: 130px;
  }
  .draw-gift-bg { width: 100%; min-height: 300px; position: relative; background: url('~@/assets/PC/InnovationContest/invite-friends.png') no-repeat; background-size: 100% 100% }
  .drawGift-state-null {
    font-size: 15px;
    margin: 50px 0;
  }
}

@media (max-width: 768px) {
  .drawModule {
    .drawGift-state img {
      width: 120px
    }
    .draw-gift-bg { min-height: 100px }
  }
}
</style>