<template>
  <div v-if="resData.IsDataState" class="EntryWorks">
    <h2 class="tc innovationContest-subtitle">决赛选手</h2>
    <div v-if="!resData.entryWorksListData.length" class="noData tc">
      <img src="@/assets/PC/profile/listNone1.png" alt="" />
    </div>
    <div class="EntryWorksList">
      <div v-if="!resData.listData.length" class="noData tc">
        <img src="@/assets/PC/profile/listNone1.png" alt="" />
      </div>
      <div v-else class="EntryWorks-item">
        <div class="EntryWorks-item-list">
          <div v-if="clientWidth > 11750" :class="resData.listData.length > 4 ? 'flex10': 'flex4'">
            <div v-for="(item,index) in resData.listData" :key="index" class="EntryWorks-list-item">
              <div class="works-content-module1">
                <div class="works-item-headUrl" style="position: relative">
                  <img src="@/assets/PC/InnovationContest/Picture-frame.png" class="works-item-headUrl-frame">
                  <div class="works-item-heat-box">
                    <div class="works-item-heat-text tc">
                      <van-icon name="fire"/>
                      {{ item.hotNum }}
                    </div>
                  </div>
                  <div class="works-item-headUrl" style="overflow: hidden">
                    <img :src="item.imgUrl" width="100%" />
                  </div>
                </div>
              </div>
              <div class="works-content flex">
                <div class="works-content-l">
                  <div class="works-icon-line"></div>
                </div>
                <div class="works-content-r cur" @click="ShowAlert(item)">
                  <div class="works-title">{{ item.title }}</div>
                  <div v-if="item.userName" class="works-company co-666 mb">
                    <b>参赛人：</b>
                    {{ item.userName }}
                  </div>
                  <div class="works-company co-666 mb">
                    <b>所属公司：</b>
                    {{ item.company }}
                  </div>
                  <div class="works-abstract co-666 hidden3">
                    <b>作品简介：</b>
                    {{ item.abstract }}
                  </div>
                </div>
              </div>
              <!-- <div class="works-like-box" v-if="resData.activeType == 0">
                <div class="works-like-item cur">
                  <img src="@/assets/PC/InnovationContest/unVoted.png" width="100%" @click="IsVoted(item.id, index, indexCount)"/>
                </div>
              </div> -->

              <!-- <div class="works-like-box" v-if="resData.activeType == 1">
                <div class="works-like-item cur" @click="IsLiked(item.id, item.isLike, index)">
                  <img src="@/assets/PC/InnovationContest/Like.png" width="100%" @click="IsLiked(item.id, index, indexCount)"/>
                </div>
              </div> -->
            </div>
          </div>
          <div v-else>
            <entry-work-swiper 
              v-if="resData.IsData"
              :configSwiper="{ prevBtn: 'prevBtn',nextBtn: 'nextBtn', listData: resData.listData, activeType: 0 }"
              
            />
            <!-- @listDataChange="listDataChange" -->
          </div>
        </div>
      </div>
    </div>
    <div v-show="IsShowAlert" class="shade flex0">
      <div class="showAlert-box">
        <el-scrollbar>
          <div class="showAlert-content">
            <div v-if="resData.activeType === 0">
              <div class="works-content-module1">
                <div style="position: relative">
                  <div class="works-item-logo" style="overflow: hidden">
                    <img :src="ShowAlertContent.userImgUrl" width="100%" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="resData.activeType === 1">
              <img :src="ShowAlertContent.imgUrl" alt="" width="100%">
            </div>
            <div class="flex mt15" >
              <div v-if="resData.activeType === 0" class="works-content-l">
                <div class="works-icon-line"></div>
              </div>
              <div class="works-content-r">
                <div v-if="resData.activeType === 0" class="works-title">{{ ShowAlertContent.title }}</div>
                <div v-if="ShowAlertContent.userName && resData.activeType === 1" class="works-company co-666 mb">
                  <b>项目人：</b>
                  {{ ShowAlertContent.userName }}
                </div>
                <div class="works-company co-666 mb">
                  <b>所属公司：</b>
                  {{ ShowAlertContent.company }}
                </div>
                <div class="works-abstract co-666">
                  <b>作品简介：</b>
                  {{ ShowAlertContent.abstract }}
                </div>
                <div class="works-contact-me co-voi flex2 mt10">
                  <span v-if="ShowAlertContent.url" class="cur co-voi" @click="checkWork(ShowAlertContent.url, ShowAlertContent.id)">
                    查看完整作品 <i class="el-icon-arrow-right"></i>
                  </span>
                </div>
              </div>
            </div>

            <div v-if="resData.activeType === 0" class="works-item-call tc"  :style="resData.activeType ? '' : 'border-bottom: 2px dashed #CD9EDE'">
              <a :href="'mailto:' + ShowAlertContent.userMailto" class="cur co-fff" @click="GetData(ShowAlertContent.id)">联系我<i class="el-icon-arrow-right"></i></a>
            </div>

            <div v-else class="works-item-call tc"  :style="resData.activeType ? '' : 'border-bottom: 2px dashed #CD9EDE'">
              <a :href="'mailto:' + ShowAlertContent.mailto" class="cur co-fff" @click="GetData(ShowAlertContent.id)">联系我<i class="el-icon-arrow-right"></i></a>
            </div>

            <div v-if="resData.activeType === 0">
              <div class="works-item-userName tc" style="margin-top:10px">{{ ShowAlertContent.userName }}</div>
              <div class="works-item-userPosition tc">{{ ShowAlertContent.userPosition }}</div>
              <div class="works-item-userCompany tc co-666">{{ ShowAlertContent.userCompany }}</div>
              <div class="works-item-userAbstract co-666">{{ ShowAlertContent.userAbstract }}</div>
            </div>
          </div>
        </el-scrollbar>
        <div class="show-close">
          <i class="el-icon-circle-close cur" @click="ShowClose"></i>
        </div>
      </div>
    </div>
    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { debounce, GetShareData } from "@/utils/common";
import { AddLikeEntries, LiveEntries_L3, Like, Finalist }  from '@/api/InnovationContest'
import EntryWorkSwiper from "./EntryWorksSwiper.vue"
import shadeAlert from '@/components/shadeAlert/index.vue'


export default {
  name: 'EntryWorksSwiper',
  data () {
    return {
      // !参赛作品数据
      resData: {
        // !接口是否返回
        IsDataState: false,
        entryWorksListData: [],
        // !作品类型切换
        activeType: 0,
        // !轮播图作品列表
        listData: [],
        // !轮播图组件重新生成
        IsData: false
      },
      // !弹窗展示内容
      ShowAlertContent: {},
      // !弹窗是否展示
      IsShowAlert: false,
      // !弹窗配置
      shadeConfig: {
        show: false, // 展示隐藏弹窗
        closeIcon: true, // 是否需要关闭按钮
        title: '',
        text: '', // 弹窗内容
        btnType: '', // 按钮形式
        btnList: []
      }
    }
  },
  mounted() {
    this.GetEntryWorks()
  },
  methods: {
    // !参赛作品接口
    GetEntryWorks() {
      Finalist().then((res) => {
        if (res.status === 1) {
          this.resData.entryWorksListData = res.data 
          if (res.data.length) {
            this.resData.listData = this.resData.entryWorksListData;
            this.resData.IsData = true
          }
          console.log(res.data.length)
          this.resData.IsDataState = true;
        } else {
          this.$toast(res.message);
        }
      });
    },
    // !参赛作品切换
    tabType(activeIndex) {
      this.resData.activeType = activeIndex;
      this.resData.listData = this.resData.entryWorksListData[activeIndex].tags;
    },
    // !展开弹窗
    ShowAlert(item) {
      this.IsShowAlert = true;
      this.ShowAlertContent = item;
    },
    // !关闭弹窗
    ShowClose() {
      this.IsShowAlert = false
      this.ShowAlertContent = {}
    },
    // // !投票
    // IsVoted(id, index, indexCount) {
    //   if (!this.$store.state.token) {
    //     this.shadeConfig = {
    //       show: true, // 展示隐藏弹窗
    //       closeIcon: true, // 是否需要关闭按钮
    //       title: '您还没有登录，<br/>请登录后再来投票吧！',
    //       text: '', // 弹窗内容
    //       btnType: 'link', // 按钮形式
    //       btnList: [
    //         {text:'已有剑盟账号，前往登录', link: '/Login?redirectUrl=' + this.$route.fullPath  + '&from=live'},
    //         {text:'没有账号，立即注册', link:'/Register?redirectUrl=' + this.$route.fullPath  + '&from=live'}
    //       ]
    //     }
    //     return false
    //   }
    //   debounce(() => {
    //     AddLikeEntries({id: id}).then(res => {
    //       this.$toast(res.message)
    //       if(res.status === 1) {
    //         this.resData.listData[indexCount].entriesItems[index].hotNum += 10
    //       }
    //     })
    //   }, 1000)
    // },
    // // !轮播图改变投票数量
    // listDataChange(current) {
    //   this.resData.listData[current.indexCount].entriesItems[current.index].hotNum+= 10
    // },
    // // !点赞
    // IsLiked(id, index, indexCount) {
    //   if (!this.$store.state.token) {
    //     this.shadeConfig = {
    //       show: true, // 展示隐藏弹窗
    //       closeIcon: true, // 是否需要关闭按钮
    //       title: '您还没有登录，<br/>请登录后再来点赞吧！',
    //       text: '', // 弹窗内容
    //       btnType: 'link', // 按钮形式
    //       btnList: [
    //         {text:'已有剑盟账号，前往登录', link: '/Login?redirectUrl=' + this.$route.fullPath  + '&from=live'},
    //         {text:'没有账号，立即注册', link:'/Register?redirectUrl=' + this.$route.fullPath  + '&from=live'}
    //       ]
    //     }
    //     return false
    //   }
    //   debounce(() => {
    //     Like({id: id}).then(res => {
    //       this.$toast(res.message)
    //       if(res.status === 1) {
    //         this.resData.listData[indexCount].entriesItems[index].hotNum += 10
    //       }
    //     })
    //   }, 1000)
    // },
     // !查看完整作品
    checkWork(url, id) {
      if(!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: '您还没有登录，<br/>请登录后再来查看作品！',
          text: '', // 弹窗内容
          btnType: 'link', // 按钮形式
          btnList: [
            {text:'已有剑盟账号，前往登录', link: '/Login?redirectUrl=' + this.$route.fullPath  + '&from=live'},
            {text:'没有账号，立即注册', link:'/Register?redirectUrl=' + this.$route.fullPath  + '&from=live'}
          ]
        }
        return false
      }
      if(this.Infos.type == 1) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: '您还不是剑盟会员，<br/>请先申请成为剑盟会员吧！',
          text: '', // 弹窗内容
          btnType: 1, // 按钮形式
          btnList: [],
          btnText: '点击成为剑盟会员'
        }
        return false
      }

      GetShareData(2, "EntryWorksSwiper", id)
      if(this.clientWidth > 750) {
        window.open(url)
      } else {
        window.location.href = url
      }
    },
    // !关闭登录指引弹窗
    closeAlertChange(state){
      this.shadeConfig.show = state
    },
    // !联系我 的数据统计
    GetData(id) {
      GetShareData(3, "EntryWorksSwiper", id)
    }
  },
  computed: {
    ...mapState(['clientWidth', 'Infos','token'])
  },
  components: {
    EntryWorkSwiper,
    shadeAlert
  }
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .EntryWorks {
    .tab-entry-type { margin-bottom: 30px; position: relative }
    .tab-entry-type ul { border-radius: 5px; overflow: hidden }
    .tab-entry-type ul li { padding: 5px; background: #f0e7fc }
    .tab-btn { padding: 12px 30px; border: 1px #f0e7fc solid; transition: all 0.3s; border-radius: 4px }
    .tab-btn-active { background: #6B18A8; color: #fff; border: 1px #6B18A8 solid }

    .EntryWorks-item-tagName { font-size: 20px; font-weight: bold; padding: 7px 16px 7px 20px; background: #F6EAFE; display: inline-block; margin-bottom: 40px; border-top-right-radius: 15px; position: relative }
    .EntryWorks-item-tagName::before {content: ''; display: block; width: 4px; height: 24px; background: #6B18A8; position: absolute; top: 10px; left: 0 }

    .EntryWorks-list-item { margin: 0 0 20px 20px; width: calc((100% - 60px) / 4); background: #fff; border: 1px #fff solid; border-radius: 5px; transition: all 0.3s; position: relative; overflow: hidden } // padding-bottom: 70px;
    .EntryWorks-list-item1 { padding-bottom: 70px }
    .EntryWorks-list-item:nth-of-type(4n + 1) { margin-left: 0 }

    .works-content { padding: 15px 10px }
    .works-content-l { padding: 10px 10px 0 0 }
    .works-icon-line { width: 40px; height: 3px; background: linear-gradient(to left, #f70756, #ffbb01) }

    .works-content-module1-other { height: 0; padding-bottom: 56%;  margin-bottom: 10px; overflow: hidden; position: relative }
    .works-content-module1 { padding: 30px; margin: 0 auto }
    .works-item-headUrl { width: 120px; height: 120px; border-radius: 50%; margin: 0 auto; background: #E4E4E4 }
    .works-item-headUrl-frame { position: absolute; left: -9px; top: -10px; width: 140px }
    .works-item-logo { width: 250px; margin: 0 auto; }
    .works-item-heat-box { position: absolute; left: 2px; bottom: -14px; width: 100%; display: flex; justify-content: center }
    .works-item-heat-text { background: #fff; min-width: 60px; height: 24px; color: #db2f28; border: 1px #db2f28 solid; border-radius: 12px; line-height: 22px; padding: 0 5px; }
    .works-item-heat-liked {background: #fff; min-width: 60px; height: 24px; color: #6B18A8; border: 1px #6B18A8 solid; border-radius: 12px; line-height: 22px; padding: 0 5px; position: absolute; bottom: 10px; left: 10px; }


    .works-title { font-weight: bold; margin-bottom: 15px }
    .works-like-box { width: 100%; position: absolute; bottom: 30px; left: 0 }
    .works-like-item { width: 133px; margin: 0 auto; transition: all 0.3s }

    .works-item-userName,
    .works-item-userPosition { font-size: 20px; font-weight: bold; line-height: 32px;  }
    .works-item-userCompany { font-size: 18px; font-weight: bold; margin-top: 10px; }
    .works-item-userAbstract { margin-top: 5px;}
    .works-item-call { padding: 10px 0 30px 0 }
    .works-item-call a { display: inline-block; padding: 10px 20px; border-radius: 40px; background: linear-gradient(45deg, #CD9DED, #3C1152) }

  }

  @media (min-width: 751px) {
    .EntryWorks {
      .EntryWorks-list-item:hover {border: 1px #6B18A8 solid; box-shadow: 0 0 15px #999 }
    }
  }

  @media (max-width: 750px) {
    .EntryWorks {
      // !参赛作品
      .tab-entry-type { margin-bottom: vw(30) }
      .tab-entry-type ul { border-radius: vw(10) }
      .tab-entry-type ul li { padding: vw(10) }
      .tab-btn { padding: vw(16) vw(92) }
      .tab-btn-active { border-radius: vw(8) }

      .EntryWorksList { margin: 0 15px }
      .EntryWorks-item-tagName { font-size: vw(28); padding: vw(14) vw(32) vw(14) vw(20);  margin-bottom: vw(30) }
      .EntryWorks-item-tagName::before { width: vw(4); height: vw(36); top: vw(14) }
    }
  }
</style>