export const emoji = [
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/weixiao.gif',
    text: '[em_new_gif_weixiao]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/happy.gif',
    text: '[em_new_gif_happy]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/like.gif',
    text: '[em_new_gif_like]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/favour.gif',
    text: '[em_new_gif_favour]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/cool.gif',
    text: '[em_new_gif_cool]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/shy.gif',
    text: '[em_new_gif_shy]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/kiss.gif',
    text: '[em_new_gif_kiss]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/cry.gif',
    text: '[em_new_gif_cry]'
  },
  { 
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/laugh&cry.gif',
    text: '[em_new_gif_laugh&cry]'
  },
  { 
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/great.gif',
    text: '[em_new_gif_great]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/sad.gif',
    text: '[em_new_gif_sad]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/hum.gif',
    text: '[em_new_gif_hum]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/love.gif',
    text: '[em_new_gif_love]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/haha.gif',
    text: '[em_new_gif_haha]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/emmm.gif',
    text: '[em_new_gif_emmm]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/silly.gif',
    text: '[em_new_gif_silly]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/xixi.gif',
    text: '[em_new_gif_xixi]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/awesome.gif',
    text: '[em_new_gif_awesome]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/candle.gif',
    text: '[em_new_gif_candle]'
  },
  {
    url: 'https://static.mudu.tv/static/emoticons/emoticons-v2-gif/pray.gif',
    text: '[em_new_gif_pray]'
  }
]