<template>
  <div class="previous-works-swiper" :class="{'isbg':IsBg}">
    <div class="previous-works-module" :class="{'isbg':IsBg}">
      <div class="previous-works-list flex4">
        <div class="works-item" v-for="item in PreviousWorksList" :key="item.id">
          <div class="works-banner">
            <img v-if="item.productImgUrl" :src="item.productImgUrl" alt="" width="100%">
            <img v-else src="@/assets/PC/InnovationContest/Innovation-contest-works.png" alt="" width="100%">
          </div>
          <div class="works-content flex">
            <div class="works-content-l">
              <div class="works-icon-line"></div>
            </div>
            <div class="works-content-r cur" @click="ShowAlert(item)">
              <div class="works-title">{{ item.title }}</div>
              <div class="works-name co-666 mb">
                <b>项目人：</b>
                {{ item.userName }}
              </div>
              <div class="works-company co-666 mb">
                <b>所属公司：</b>
                {{ item.company }}
              </div>
              <div class="works-abstract co-666 hidden3">
                <b>作品简介：</b>
                {{ item.abstract }}
              </div>
            </div>
            <div class="works-contact-me co-voi flex2">
              <span v-if="item.url" class="cur co-voi" @click="checkWork(item.url, item.id)">
                查看完整作品 <i class="el-icon-arrow-right"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 全部内容的弹窗 -->
    <div v-show="IsShowAlert" class="shade flex0">
      <div class="showAlert-box">
        <el-scrollbar>
          <div class="showAlert-content">
            <div class="ShowAlert-banner">
              <img v-if="ShowAlertContent.imgUrl" :src="ShowAlertContent.imgUrl" alt="" width="100%">
              <img v-else src="@/assets/PC/InnovationContest/Innovation-contest-works.png" alt="" width="100%">
            </div>
            <div class="works-content flex" style="padding: 15px 0">
              <div class="works-content-r">
                <div class="works-name co-666 mb">
                  <b>项目人：</b>
                  {{ ShowAlertContent.userName }}
                </div>
                <div class="works-company co-666 mb">
                  <b>所属公司：</b>
                  {{ ShowAlertContent.company }}
                </div>
                <div class="works-abstract co-666">
                  <b>作品简介：</b>
                  {{ ShowAlertContent.abstract }}
                </div>
                <div class="works-contact-me co-voi flex2">
                  <span v-if="ShowAlertContent.url" class="cur co-voi" @click="checkWork(ShowAlertContent.url,ShowAlertContent.id)">
                    查看完整作品 <i class="el-icon-arrow-right"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
        <div class="show-close">
          <i class="el-icon-circle-close cur" @click="ShowClose"></i>
        </div>
      </div>
    </div>
    <!-- 登录执行弹窗 -->
    <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange"/>
  </div>
</template>

<script>
import { mapState } from "vuex"
import shadeAlert from '@/components/shadeAlert/index.vue'
import { GetShareData } from '@/utils/common.js'

import {
  Swiper as SwiperClass,
  Pagination,
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import "swiper/swiper-bundle.min.css";
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);

export default {
  name: 'PreviousWorksSwiper',
  props: ['PreviousWorksList','IsBg'],
  data() {
    return {
      swiperOption: {
        slidesPerView: 4,
        navigation: {
          nextEl: ".works-button-next",
          prevEl: ".works-button-prev",
        },
      },
      swiperOption_m: {
        slidesPerView: 1,
        navigation: {
          nextEl: ".works-button-next",
          prevEl: ".works-button-prev",
        },
      },
      // !弹窗展示内容
      ShowAlertContent: {},
      // !弹窗是否展示
      IsShowAlert: false,
      // !弹窗配置
      shadeConfig: {
        show: false, // 展示隐藏弹窗
        closeIcon: true, // 是否需要关闭按钮
        title: '',
        text: '', // 弹窗内容
        btnType: '', // 按钮形式
        btnList: []
      }
    }
  },
  computed: {
    ...mapState(['clientWidth', 'Infos','token'])
  },
  methods: {
    // !展开弹窗
    ShowAlert(item) {
      this.IsShowAlert = true;
      this.ShowAlertContent = item;
    },
    // !关闭弹窗
    ShowClose() {
      this.IsShowAlert = false
      this.ShowAlertContent = {}
    },
    // !查看完整作品
    checkWork(url, id) {
      if(!this.token) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: '您还没有登录，<br/>请登录后再来查看作品！',
          text: '', // 弹窗内容
          btnType: 'link', // 按钮形式
          btnList: [
            {text:'已有剑盟账号，前往登录', link: '/Login?redirectUrl=' + this.$route.fullPath  + '&from=live'},
            {text:'没有账号，立即注册', link:'/Register?redirectUrl=' + this.$route.fullPath  + '&from=live'}
          ]
        }
        return false
      }
      if(this.Infos.type == 1) {
        this.shadeConfig = {
          show: true, // 展示隐藏弹窗
          closeIcon: true, // 是否需要关闭按钮
          title: '您还不是剑盟会员，<br/>请先申请成为剑盟会员吧！',
          text: '', // 弹窗内容
          btnType: 1, // 按钮形式
          btnList: [],
          btnText: '点击成为剑盟会员'
        }
        return false
      }

      GetShareData(2, "PreviousWorksSwiper", id)
      if(this.clientWidth > 750) {
        window.open(url)
      } else {
        window.location.href = url
      }
    },
    // !关闭登录指引弹窗
    closeAlertChange(state){
      this.shadeConfig.show = state
    },
  },
  components: {
    Swiper,
    SwiperSlide,
    shadeAlert
  },
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .previous-works-swiper {
    padding-top: 159px; position: relative;
    &.isbg{ padding: 0; }
    .previous-works-module { background: rgba(238, 130,238, 0.3);
      &.isbg{ background: none;
        .previous-works-list{ transform: none; }
      }
    }
    .previous-works-list { margin: 0 auto; max-width: 1200px; transform: translateY(-159px) }
    .works-item { margin: 0 0 20px 20px; width: calc((100% - 75px) / 4); background: #fff; padding-bottom: 30px; position: relative; }
    .works-item:nth-of-type(4n+1) { margin-left: 0 }
    .works-banner { width: 281px; height: 159px; overflow: hidden }

    .works-content { padding: 15px 10px; }
    .works-content-l { padding: 10px 10px 0 0 }

    .works-icon-line { width: 40px; height: 3px; background: linear-gradient(to left, #f70756, #ffbb01) }
    .works-title { font-weight: bold; margin-bottom: 15px }
    .works-contact-me {position: absolute; bottom: 15px; left: 70px; }

    .works-swiper-item { width: calc(100% - 20px); height: 100%; margin: 0 auto; background: #fff; padding-bottom: 30px; }
    .works-swiper-banner { width: 280px; height: 158px; overflow: hidden }

    .works-button-prev { top: inherit; bottom: -60px; left: calc((50% - 50px)) }
    .works-button-next { top: inherit; bottom: -60px; right: calc((50% - 50px)) }
    .works-button-prev,  
    .works-button-next {
      width: 40px; height: 40px; border-radius: 50%; background: #999; transition: all 0.1s;
    }
    .works-button-prev::after,.works-button-next::after { font-size: 15px; color: #Fff; }

    .showAlert-content .works-contact-me { position: static; padding-top: 10px }
  }

  @media (max-width: 750px) {
    .previous-works-swiper {
      .works-banner,
      .works-swiper-banner { width: 100%; height: vw(400); }

      .works-item,
      .works-swiper-item { width: calc(100% - 30px);margin: 0 auto 20px; }
      .works-item:nth-of-type(4n+1) { margin: 0 auto 20px; }
      .works-contact-me{left:75px}
    }
  }
</style>