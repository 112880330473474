<template lang="pug">
- let heartIcon = "M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z";
#scr
  div.btn-love(@click.self="sendLove")
    svg.love-icon(viewBox="0 0 24 24")
      path(d=heartIcon)
    transition-group(name="love-move" tag="div")
      .send-love(
        ref="love-item"
        :class="love.move"
        :key="'loveItem' + i"
        @animationend="fadeLove(i)"
        v-if="love.show === true"
        v-for="love, i in loves"
      )
        svg.love-icon(
          viewBox="0 0 24 24"
          :class="{" +
                 "'icon-red': love.color === 'red', " +
                 "'icon-orange': love.color === 'orange', " +
                 "'icon-yellow': love.color === 'yellow', " +
                 "'icon-green': love.color === 'green', " +
                 "'icon-blue': love.color === 'blue', " +
                 "'icon-violet': love.color === 'violet'" +
                 "}"
        )
          path(d=heartIcon)
</template>

<script>
const LOVE_COLOR = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'violet'
];
  
// Generates random number.
function random(min, max) {
  return Math.round(Math.random() * (max - min)) + min;
}
  
// Creates 'love' element and put it at the same
// position of the 'heart' inside the button.
function createLove(vue) {
  let color = random(0, LOVE_COLOR.length - 1);
  let move = random(0, 95);
  
  vue.loves.push({
    color: LOVE_COLOR[color],
    move: "send-love-move-" + move,
    show: true
  });
}

export default {
  data() {
    return {
      loves: []
    };
  },
  methods: {
    sendLove(evt) {
      let elm = evt.target.children[0];
      let rect = elm.getBoundingClientRect();
      let em = parseFloat(getComputedStyle(elm).fontSize);
      
      createLove(this);
    },
    fadeLove(index) {
      if (this.loves[index] !== null) {
        this.loves[index].show = false;
      }
    }
  }
};
</script>

<style lang="scss">
$font-size: 16px;
  
$main-color: #e6093a;
  
$screen-background-color: #ffa3e2;

$btn-love-background-color: $main-color;
$btn-love-padding: 1.25em;
$btn-love-border-color: darken($btn-love-background-color, 10%);
$btn-love-float-size: 0.5em;
$btn-love-border: solid 0.35em $btn-love-border-color;
$btn-love-box-shadow: 0 $btn-love-float-size $btn-love-border-color;
$btn-love-transition-time: 175ms;
$btn-love-transition: box-shadow $btn-love-transition-time ease-out,
  transform $btn-love-transition-time ease-out;

$love-icon-fill: #ffffff;
$love-icon-width: 1.5em;
$love-icon-height: 1.4em;

$send-love-fill-red: #ff1f57;
$send-love-fill-orange: #ff9645;
$send-love-fill-yellow: #ffea73;
$send-love-fill-green: #7fff7a;
$send-love-fill-blue: #61a8ff;
$send-love-fill-violet: #cf82ff;
  
$send-love-time: 2000ms;

#screen {
  font-size: $font-size;
  background-color: $screen-background-color;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.btn-love {
  transform: translate(-0.5px, 0.5px);
  outline: 0;
  position: relative;
  transition: $btn-love-transition;
  &:active {
    box-shadow: 0 0 $btn-love-border-color;
    transform: translateY($btn-love-float-size);
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }
  svg {
    fill: $love-icon-fill;
  }
}

.send-love {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.love-move-leave-active {
    display: none;
  }
  svg.icon-red {
    fill: $send-love-fill-red;
  }
  svg.icon-orange {
    fill: $send-love-fill-orange;
  }
  svg.icon-yellow {
    fill: $send-love-fill-yellow;
  }
  svg.icon-green {
    fill: $send-love-fill-green;
  }
  svg.icon-blue {
    fill: $send-love-fill-blue;
  }
  svg.icon-violet {
    fill: $send-love-fill-violet;
  }
}

.love-icon {
  width: $love-icon-width;
  height: $love-icon-height;
  vertical-align: top;
}
  
$index: 0;
  
@for $x from 8 through 19 {
  @for $y from 10 through 17 {
    @keyframes send-love-x-#{$index} {
      0% {
        left: calc(50% - 0em);
      }
      100% {
        left: calc(50% - #{$x - 14}em);
      }
    }

    @keyframes send-love-y-#{$index} {
      0% {
        top: calc(50% - 0em);
      }
      50%, 60% {
        top: calc(50% - #{$y}em);
      }
      100% {
        top: calc(50% - #{$y * 0.8}em);
      }
    }
    
    .send-love.love-move-enter-active.send-love-move-#{$index} {
      animation: send-love-x-#{$index} $send-love-time ease-out forwards
               , send-love-y-#{$index} $send-love-time ease-out forwards
               , send-love-hide $send-love-time ease-out forwards;
    }
    
    $index: $index + 1;
  }
}
  
@keyframes send-love-hide {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5);
  }
}
</style>